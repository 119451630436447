import React, { useEffect, useState } from "react";
import "./style.css";
import * as Fi from "react-icons/fi";
import axios from "../../utils/API";
import { useHistory } from "react-router-dom";
import { Input, message } from "antd";
import Form from "antd/lib/form/Form";
const LoginNews = () => {
  const [iduser, setiduser] = useState("");
  const [passuser, setpassuser] = useState("");
  const [loding, setloding] = useState(false);
  const userid = localStorage.getItem("idusee");
  const LOGINUSER = localStorage.getItem("LOGINUSER");
  const history = useHistory();

  useEffect(() => {
    try {
      const getperuser = async (userid) => {
        if (LOGINUSER === "true") {
          // setisAuthenticated(false)

          const userone = await axios.post(`users/${userid}`);

          if (userone.data.role === "مدير نظام") {
            
            history.push("/HomeAdminManage", { from: "Login" });
          } else {
            history.push("/HomeUserManage", { from: "Login" });
          }
        } else {
          //    window.location.reload();

          history.push("/login", { from: "ProtectedRoute" });
        }
      };

      getperuser(userid);
    } catch (error) {
      console.log("");
    }
  }, [LOGINUSER]);

  const gotohome = async () => {
    history.push("/", { from: "Login" });
  };

  const loginuser = async () => {
    if (userid === "") {
      message.warn("حقل رقم المستخدم فارغ");
    } else if (passuser === "") {
      message.warn("حقل كلمة المرور فارغ");
    } else {
      setTimeout(async() => {
         const loginuser = await axios.post(`users/login/${iduser}&${passuser}`);

  
      if (loginuser.status === 201) {
        message.warn(loginuser.data.message);
      } else {
            localStorage.setItem("token", loginuser.data.token);
      localStorage.setItem("idusee", loginuser.data.iduser);
      localStorage.setItem("LOGINUSER", "true");
      localStorage.setItem("idmustakdem", loginuser.data.iduser);
        window.location.reload();
      }
      }, 500);
     
    }
  };

  return (

    <div style={{ background: "#fff" }}>
    {/*<img src={window.location.origin + "/img/lui.jpg"} width="100%" />*/} 
    

      
        <Form onFinish={loginuser} >

          <div className="contanerloginnew">

            <div className="" style={{ zIndex: 58555 }}>
              <div
                className=""
                alt="logo"
                style={{ textAlign: "center", margin: 15 }}
              >
                <h3 style={{ fontWeight: 700, color: "#fff", userselect: "none" }}>
                  {" "}
            المحاسب القانوني والمستشار المالي
          </h3>
                <p
                  style={{
                    fontFamily: "'Aref Ruqaa', serif",
                    fontSize: 40,
                    color: "rgb(255 255 255)",
                    marginBottom: 0,
                    userselect: "none"
                  }}
                >
                  سعدي محبوُبة
          </p>
              </div>
            </div>
            <div>
              <input
                className="inputnewlogin"
                type="number"
                placeholder="رقم المستخدم"
                
                value={iduser}
                onChange={(e) => setiduser(e.target.value)}
              />
              <input
                className="inputnewlogin"
                type="password"
                placeholder="كلمة المرور"
                
                value={passuser}
                onChange={(e) => setpassuser(e.target.value)}
              />

              <button className="btn-grad" type="submit">
                تسجيل الدخول
            </button>
            </div>
          </div>
        </Form>
      
    </div>

  );
};

export default LoginNews;
