import React, { useEffect, useState } from "react";
import { AiFillMoneyCollect, AiOutlineUser } from "react-icons/ai";
import { BiCalendarEvent, BiLogOutCircle } from "react-icons/bi";
import { FaUserCircle } from "react-icons/fa";


import { MdAttachMoney, MdComment, MdFilter, MdHome, MdMessage, MdNotifications, MdNotificationsActive } from "react-icons/md";

import { BsChatSquareQuoteFill } from "react-icons/bs";



import { useHistory } from "react-router-dom";
import { actiontype } from "../../reducer/Reducer";
import { useStateValue } from "../../reducer/StateProvider";
import axios from "../../utils/API";
import { message } from "antd";

const SlideUsers = ({
  clas = "SlideUsershidde"
}) => {
  const history = useHistory();
  const [{ clickiconmenufolder_REDUCER }, dispatch] = useStateValue();
  const [managerusers, setmanagerusers] = useState(false);
  const [username, setusername] = useState("");
  const [notyuser, setnotyuser] = useState(0);
  const [amaluser, setamaluser] = useState(0);

  const [userphone, setuserphone] = useState("");
  const [Bakfrom, setBakfrom] = useState("");

  const iduser = localStorage.getItem("idusee");
  const idmk = localStorage.getItem("idmustakdem");


  const userone = async (idus) => {
    const data = await axios.post(`users/${idus}`);
   
    if (data.data.role === "مدير نظام") {
      setmanagerusers(true);
      setBakfrom("/FilesUsers");
    } else {
      setmanagerusers(false);
      setBakfrom("/Mustakdem");
    }
  };

  const userones = async (iduss) => {
    const data = await axios.post(`users/${iduss}`);
    setusername(data.data.username);
    setuserphone(data.data.phone);
    
  };




  useEffect(() => {
    userone(iduser);
    userones(idmk);
  }, [iduser,idmk]);


  useEffect(() => {
    const usermustakem = async (idmkl) => {
      const data = await axios.post(`users/${idmkl}`);
      setusername(data.data.username);
       const datanoty = await axios.get(`NotyUser/${idmkl}`);
      if (datanoty.data[0].notify.length > 0)
      {
        
        const lennoty = datanoty.data[0].notify.filter(res => res.red === false).map(res => res)
        setnotyuser(lennoty.length);
      }
      else {
        setnotyuser(0);
      }

      const dataamal = await axios.get(`Amal`);
      if (dataamal.data[0].amal.length > 0)
      {
        
        const lenamal = dataamal.data[0].amal.filter(res => res.red === false).map(res => res)
        setamaluser(lenamal.length);
      }
      else {
        setamaluser(0);
      }

    }
    usermustakem(idmk);

  });

 

  const loguotuser = async () => {
    localStorage.setItem("LOGINUSER",false);
    localStorage.removeItem("idusee");
    localStorage.removeItem("token");


    history.push("/login", { from: Bakfrom });

    dispatch({
      type: actiontype.SET_OVERMENUE,
      items: false,
    });
  };

  const gotoh = () => {
    history.push("/", { from: Bakfrom });
    dispatch({
      type: actiontype.SET_OVERMENUE,
      items: false,
    });
  }

  const gotoMaliaUser = () => {
    history.push("/MaliaUser", { from: Bakfrom });
    dispatch({
      type: actiontype.SET_OVERMENUE,
      items: false,
    });
  }




  const gotomanageuser = () => {
    history.push("/usersmange", { from: "/FilesUsers" });

    dispatch({
      type: actiontype.SET_OVERMENUE,
      items: false,
    });
  };


  const gotoamal = () => {
    history.push("/Amal", { from: "/FilesUsers" });

    dispatch({
      type: actiontype.SET_OVERMENUE,
      items: false,
    });
  };

  

  const gotomyfiles = async () => {



    const userone = await axios.post(`users/${iduser}`);
    if (userone.data.role === "مدير نظام") {
      history.push("/FilesUsers", { from: "FilesUsers" });
    } else {
      history.push("/Mustakdem", { from: "FilesUsers" });
    }

    dispatch({
      type: actiontype.SET_OVERMENUE,
      items: false,
    });
  };

  const gotomaliauser =async () => {
   
    const userone = await axios.post(`users/${iduser}`);
    if (userone.data.role === "مدير نظام") {
      history.push("/Malia", { from: "FilesUsers" });
        localStorage.setItem("idwsel", 0);
    } else {
       
    }

    dispatch({
      type: actiontype.SET_OVERMENUE,
      items: false,
    });
  }

  const gotonoty =async () => {
   
    const userone = await axios.post(`users/${iduser}`);
    if (userone.data.role === "مدير نظام") {
      history.push("/NotiFy", { from: "FilesUsers" });
      
    } else {
       
    }

    dispatch({
      type: actiontype.SET_OVERMENUE,
      items: false,
    });
  }

  const gotonotyuser =async () => {
   
   
    
      history.push("/NotyUser", { from: "FilesUsers" });

    dispatch({
      type: actiontype.SET_OVERMENUE,
      items: false,
    });
  }

  
const gotoamaluser=()=>{
  history.push("/Amaluser", { from: "FilesUsers" });

    dispatch({
      type: actiontype.SET_OVERMENUE,
      items: false,
    });
}

 
  return (
    <div className={clickiconmenufolder_REDUCER ? clas : "SlideUsershidde"}>
      <FaUserCircle size={40} />
      <h5>{username}</h5>
      <h5>{userphone}</h5>
      <h5>------------</h5>
 <div
        className="slidbtn"
        style={{ cursor: "pointer", background: "#d8be4a"}}
        onClick={loguotuser}
      >
        <h5>تسجيل الخروج</h5> <BiLogOutCircle />
      </div>
      <h5>------------</h5>
      {managerusers ? (
        <div>
        <div
          className="slidbtn"
          style={{ cursor: "pointer" }}
          onClick={gotomanageuser}
        >
          <h5>ادارة المستخدمين</h5> <AiOutlineUser />
          </div>
          
          <div
          className="slidbtn"
          style={{ cursor: "pointer",marginTop:5}}
          onClick={gotoamal}
        >
          <h5>جدول الاعمال</h5> <BiCalendarEvent />
          <div className="notiuser">{amaluser}</div>

          </div>
          
        
        <div
          className="slidbtn mt-1"
          style={{ cursor: "pointer",marginTop:5}}
          onClick={gotomaliauser}
        >
          <h5>اضافة مبلغ</h5> <AiFillMoneyCollect />
          </div>

          <div
        className="slidbtn mt-1"
        style={{ cursor: "pointer" }}
        onClick={gotonoty}
      >
        <h5>اضافةالأشعارات</h5> <MdNotifications />
      </div>

          <h5 style={{color:"#fff"}}>------------</h5>
          
          </div>

          

      ) : (
        ""
      )}


<div
        className="slidbtn"
        style={{ cursor: "pointer" }}
        onClick={gotoamaluser}
      >
        <h5>اضافة عمل كتابة</h5> <MdNotificationsActive />
      </div>

      <div
        className="slidbtn"
        style={{ cursor: "pointer" }}
        onClick={gotomyfiles}
      >
        <h5>الملفات</h5> <MdFilter />
      </div>


      <div
        className="slidbtn"
        style={{ cursor: "pointer" }}
        onClick={gotoMaliaUser}
      >
        <h5>كشف حساب</h5> <MdAttachMoney />
      </div>
     
      
     
        

      <div
        className="slidbtn"
        style={{ cursor: "pointer" }}
        onClick={gotonotyuser}
      >
        <h5>الأشعارات</h5> <MdNotifications />
        <div className="notiuser">{notyuser}</div>
      </div>

      <div
        className="slidbtn d-none"
        style={{ cursor: "pointer" }}
        onClick={() => message.info("قريباً")}
      >
        <h5>الاعلانات</h5> <MdMessage />
      </div>

      <div
        className="slidbtn d-none"
        style={{ cursor: "pointer" }}
        onClick={() => message.info("قريباً")}
      >
        <h5>الطلبات</h5> <BsChatSquareQuoteFill />
      </div>


      <div
        className="slidbtn"
        style={{ cursor: "pointer" }}
        onClick={gotoh}
      >
        <h5>الصفحةالرئيسية</h5> <MdHome />
      </div>
      <div
        className="slidbtn d-none"
        style={{ cursor: "pointer" }}
        onClick={() => message.info("قريباً")}
      >
        <h5>تواصل معنا</h5> <MdComment />
      </div>

     


    </div>
  );
};

export default SlideUsers;
