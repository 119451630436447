import React, { useState } from 'react'
import { AiFillCloseCircle } from 'react-icons/ai';
import { actiontype } from '../../reducer/Reducer';
import { useStateValue } from '../../reducer/StateProvider';
import axios from "../../utils/API";
import openMessage, { Wrongmessage } from './openMessage';

const AddNewFolder = () => {



  const [, dispatch] = useStateValue();
  const [filename, setfilename] = useState("");

  const userid = localStorage.getItem("idmustakdem");

  const closerenameform = () => {
    dispatch({
      type: actiontype.SET_OVERMENUE,
      items: false,
    });

    dispatch({
      type: actiontype.SET_SHOWADDFOLDERFORM,
      items: false,
    });
  }

  const Addfolder = () => {
    //const userone = await axios.post(`users/${1}`);

    try {

      if (filename === "") {
        //  alert("يرجى ادخال اسم المجلد")
        Wrongmessage("يرجى ادخال اسم المجلد");
      }
      else {


        const idfold = Math.floor(Math.random() * 69566545);
        axios
          .post("folder", {
            iduser: userid,
            namefolder: filename,
            id: idfold,
            empollyadd: "aso",
            hystory: "",
          })
          .then();

        closerenameform();
        openMessage();
        //alert("تم اضافة مجلد جديد");

      }


    } catch (error) {
      //console.log(error);
    }


  }



  return (
    <div className="rename">

      <div className="renameheader"><h4>اضافة مجلد جديد</h4> <AiFillCloseCircle onClick={closerenameform} size={30} /></div>

      <input placeholder="اسم مجلد السنة المالية مثل السنة المالية 2021" className="inputrename" type="text" value={filename} onChange={(e) => setfilename(e.target.value)} />

      <div className="btnrename">
        <button onClick={Addfolder}>اضافة</button> <button onClick={closerenameform}>الغاء</button>
      </div>




    </div>
  )
}

export default AddNewFolder
