import React, { useEffect, useState } from "react";
import "./stylemangeusermobail.css";

import { AiOutlineFileSearch } from "react-icons/ai";
import { ImAddressBook } from "react-icons/im";
import { FaSearchDollar } from "react-icons/fa";
import { MdNotificationsActive } from "react-icons/md";
import { SiHomeassistant } from "react-icons/si";
import { RiLogoutCircleRFill } from "react-icons/ri";
import axios from "../../utils/API";
import { useHistory } from "react-router";




const HomeAdminManage = () => {

    const [username, setusername] = useState("");
    const idmk = localStorage.getItem("idusee");

  
    const [notyuser, setnotyuser] = useState(0);
    const history = useHistory();

const puser = async () => {
    const userone = await axios.post(`users/${idmk}`);
    if (userone.data.role === "مستخدم") {
      history.push("/HomeUserManage", { from: "ProtectedRoute" });
    } else {
    }
  };
  puser();
    const userones = async (iduss) => {
    const data = await axios.post(`users/${iduss}`);
      setusername(data.data.username);
     
          const datanoty = await axios.get(`Amal`);
      if (datanoty.data[0].amal.length > 0)
      {
        
        const lennoty = datanoty.data[0].amal.filter(res => res.red === false).map(res => res)
        setnotyuser(lennoty.length);
      }
      else {
        setnotyuser(0);
      }
  };




  useEffect(() => {
      userones(idmk);
      
  }, [idmk]);

    const gotoamaluser=()=>{
    history.push("/addusers", { from: "HomeUserManage" });
    }

     const GoToFiles=()=>{
    history.push("/usersmange", { from: "HomeUserManage" });
    }

    const gotoMaliaUser = () => {
    history.push("/Amal", { from: "HomeUserManage" });
    }
    const gotonotyuser =async () => {
      history.push("/NotiFyAllUser", { from: "HomeUserManage" });

  }
    const loguotuser = async () => {
    localStorage.setItem("LOGINUSER",false);
    localStorage.removeItem("idusee");
    localStorage.removeItem("token");

    history.push("/login", { from: "HomeUserManage"  });

  };
    return (
      
      <div style={{height: "100vh",background:"linear-gradient(0deg, rgb(203, 225, 228) 0%, rgb(255 255 255) 100%)" }}>
         <img
            src={window.location.origin + "/img/bkdash.svg"}
            className="imgbkdash"
            alt="imgbkdash"
          />
        <div className="MainContainer2">
              مرحبا بك : {username}
          </div>
      <div className="MainContainer">
          

      <div
        className="box"
        style={{ backgroundColor: "rgb(35 49 130)", color: "#fff" }}
      onClick={GoToFiles}>
        <AiOutlineFileSearch size={50} />
        <span>المستخدمين</span>
      </div>
      <div
        className="box"
        style={{ backgroundColor: "#e89925", color: "#fff" }}
       onClick={gotoamaluser}>
        <ImAddressBook size={40} />
        <span>اضافة مستخدم</span>
      </div>
      <div
        className="box"
        style={{ backgroundColor: "#de3b07d6", color: "#fff" }}
       onClick={gotoMaliaUser}>
            <FaSearchDollar size={50} />
             <div className="notiuser2">{notyuser}</div>
        <span>جدول الاعمال</span>
      </div>
      <div
        className="box"
        style={{ backgroundColor: "#06803d", color: "#fff" }}
       onClick={gotonotyuser}>
                    <MdNotificationsActive size={50} />
        <span>اضافة اشعارت</span>
      </div>
    
      <div
        className="box"
        style={{ backgroundColor: "rgb(64 64 64)", color: "#fff" }}
      onClick={loguotuser}>
        <RiLogoutCircleRFill size={50} />
        <span>تسجيل الخروج</span>
      </div>
            </div>
            </div>
  );
};

export default HomeAdminManage;
