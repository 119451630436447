import React, { useEffect, useState } from 'react';
import { RiMenuFill } from 'react-icons/ri';
import './style.css';
import { actiontype } from "../../reducer/Reducer";
import { useStateValue } from "../../reducer/StateProvider";
import SlideUsers from '../NewUserForm/SlideUsers';
import axios from "../../utils/API";
import {   InputNumber, message, Progress} from 'antd';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';



const Malia = () => {

     
    const idwsel = localStorage.getItem("idwsel");
    
    const history = useHistory();
    const [progess, setprogess] = useState(0);
    const iduser = localStorage.getItem("idusee");
    const idmk = localStorage.getItem("idmustakdem");

  const puser = async () => {
    const userone = await axios.post(`users/${iduser}`);
    if (userone.data.role === "مستخدم") {
      history.push("/HomeUserManage", { from: "ProtectedRoute" });
    } else {
    }
  };
  puser();
    const [username, setusername] = useState("");
    const [MSG, setMSG] = useState("");
    const [btnadd, setbtnadd] = useState(false);
    
    const [datewsel, setdatewsel] = useState("");
    const [mblg, setmblg] = useState("");
    const [amla] = useState("دينار عراقي");
    const [beyan, setbeyan] = useState("");
    const [key, setkey] = useState(idwsel);
    
    
    

    const [addmblg, setaddmblg] = useState(true);
    const [tsdedmbla, settsdedmbla] = useState(true);
    const [gotomal, setgotomal] = useState("0");


    
     const [detailsopen, setdetailsopen] = useState(false);
    const [showslideuser, setshowslideuser] = useState(false);

   

    const [
        {
            clickiconmenufolder_REDUCER

        },
        dispatch,
    ] = useStateValue();

    const setoverflowmenu = () => {
        dispatch({
            type: actiontype.SET_OVERMENUE,
            items: false,

        });

        dispatch({
            type: actiontype.SET_SHOWREdate,
            items: false,
        });

        dispatch({
            type: actiontype.SET_SHOWADDFOLDERFORM,
            items: false,
        });
        setshowslideuser(false);
    };
    const showsleider = async () => {

        setshowslideuser(true);
        dispatch({
            type: actiontype.SET_OVERMENUE,
            items: true,
        });

    };


    useEffect(() => {
        //setkey(idwsel);
        const usermustakem = async () => {
            const data = await axios.post(`users/${idmk}`);
            setusername(data.data.username);

        }
        usermustakem(idmk);

    }, [idmk]);


  





    


    const AddMblagTouser = () => {
        setaddmblg(true);
        settsdedmbla(false);
        setdetailsopen(true);
        setgotomal("1");
       // NewAdd();
    }

    const TsdedMblagTouser = () => {
        setaddmblg(false);
        settsdedmbla(true);
        setdetailsopen(true);
        setgotomal("1");
       // NewAdd();
    }

    useEffect(() => {
    if (progess === 100) {
      setTimeout(() => {
         message.success(MSG);
        setprogess(0);
      }, 1000);
    }
    }, [progess,MSG]);
    
    const NewAdd = () => {
         setkey(uuidv4());
        setbtnadd(false);
        setmblg("");
        setbeyan("");
        setdatewsel("");

    }

    const calncel = () => {
         setgotomal("0");
        NewAdd();
    }

    const AddWasel = () => {
    
        
        if (datewsel==="") {
            message.error("يرجى تحديد التاريخ")
        }
        else if (mblg==="") {
            message.error("يرجى تحديد المبلغ")
        }
        else
        {
            
            
                if (addmblg===true)
                {
                    axios.post(
                        "Malia/",
                        {
                            iduser: idmk,
                            id: uuidv4(),
                            date: datewsel,
                            mden: mblg,
                            daen: 0,
                            amla: amla,
                            byean: beyan,
                            useradd: iduser
         
                        },
                        {
                            onUploadProgress: (progressEvent) =>
                                //console.log(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                                setprogess(
                                    Math.round((progressEvent.loaded * 100) / progressEvent.total)
                                ),
                        }
                    );
                    setMSG("تم اضافة المبلغ");
                    setbtnadd(true);
            }
            else {
               axios.post(
                        "Malia",
                        {
                            iduser: idmk,
                            id: uuidv4(),
                            date: datewsel,
                            mden: 0,
                            daen: mblg,
                            amla: amla,
                            byean: beyan,
                            useradd: iduser
         
                        },
                        {
                            onUploadProgress: (progressEvent) =>
                                //console.log(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                                setprogess(
                                    Math.round((progressEvent.loaded * 100) / progressEvent.total)
                                ),
                        }
                    );
                    setMSG("تم اضافة المبلغ");
                     setbtnadd(true);
            }

        }
      
    }

    const UpdateWasel = () => {
    
        
        if (datewsel==="") {
            message.error("يرجى تحديد التاريخ")
        }
        else if (mblg==="") {
            message.error("يرجى تحديد المبلغ")
        }
        else
        {
           if (addmblg===true)
                {
                    axios.put(
                        "Malia/",
                        {
                            iduser: idmk,
                            key: key,
                            date:datewsel,
                            mden: mblg,
                            daen: 0,
                            amla: amla,
                            byean: beyan,
                            useradd: iduser
         
                        },
                        {
                            onUploadProgress: (progressEvent) =>
                                //console.log(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                                setprogess(
                                    Math.round((progressEvent.loaded * 100) / progressEvent.total)
                                ),
                        }
                    );
                     setMSG("تم تعديل الوصل");
                    //setbtnadd(true);
            }
            else {
               axios.put(
                        "Malia",
                        {
                            iduser: idmk,
                            key: key,
                            date: datewsel,
                            mden: 0,
                            daen: mblg,
                            amla: amla,
                            byean: beyan,
                            useradd: iduser
         
                        },
                        {
                            onUploadProgress: (progressEvent) =>
                                //console.log(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                                setprogess(
                                    Math.round((progressEvent.loaded * 100) / progressEvent.total)
                                ),
                        }
               );
               
                     setMSG("تم تعديل الوصل");
                     //setbtnadd(true);
            }
           
        }
      
    }

    const deletewsel = async () => {
        try {
              await axios.delete(`Malia/${idmk}&${key}`, {
      onUploadProgress: (progressEvent) =>
        //console.log(Math.round((progressEvent.loaded * 100) / progressEvent.total));
        setprogess(
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        ),
        });
         setMSG("تم حذف الوصل");
          NewAdd();
        } catch (err) {
            console.error(err)
        }
      
       
        
       
           
 
    }

    const gotokshfhusab = () => {
        history.push("/MaliaUser", { from: "Malia" });
    }

   

    
    return (
        <div>

            <div className="fixed">


                <div className="navbar">

                    <div className="logo">
                        <img
                            src={window.location.origin + "/img/logo.png"}
                            style={{ height: "2em" }}
                            className="logoinuserform"
                            alt="logo"
                        />
                    </div>
                    <h3 style={{ color: "#FFF" }}>قسم المالية</h3>
                    <div className="iconright_malia">

                        <div style={{ textAlign: "center" }}>

                            <RiMenuFill
                                onClick={showsleider}
                                style={{ cursor: "pointer" }}
                                size={25}
                            />
                            <div>قائمة</div>
                        </div>

                    </div>



                </div>

                <div className="header2" style={{ justifyContent: "center" }}>كشف حساب المستخدم : {username}
                </div>

                <div className={gotomal==="0"? "mablaguser" : "d-none"}>

                       <div className="textmblg">
                        يرجى اختيار نوع المبلغ هل هو دين على المستخدم او تسديد من قبل المستخدم
                </div>
                    
                    <div className={addmblg ? "btnaddmblg" : "btnaddmblg " } onClick={AddMblagTouser}>اضافة مبلغ</div>
                     <div className={tsdedmbla ? "btntsdedmblg" : "btntsdedmblg " } onClick={TsdedMblagTouser}>تسديد مبلغ</div>
                </div >

             

                <div className={detailsopen ? "detailmblg" : "detailmblgopctzero"}>
                    <div style={{width:"100%",textAlign:"center"}}>
                       
                    <div>  <span>التاريخ :</span> </div>
                        <input value={datewsel} onChange={(e)=>setdatewsel(e.target.value)} className="inputmblg" type="date"  required pattern="\d{1,2}/\d{1,2}/\d{4}"/>
                     

                    </div>

                    <div style={{width:"100%",textAlign:"center"}}>
                       
                    <div>  <span>المبلغ :</span> </div>
                        
                        <InputNumber
                            value={mblg} onChange={(value)=>setmblg(value)} 
                           style={{width: "80%",
    padding: "5px",
    textalign: "center",
    outline: "none",
    border: "1px solid #2f2f2f",
    background: "#fff",
    borderradius:" 2px",
    fontweight: "800"}}
    
      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
    parser={value => value.replace(/\D/g, "")}
    />
                    </div>

                   
                      <div style={{width:"100%",textAlign:"center"}}>
                       <div> <span >البيان :</span> </div>
                        <textarea value={beyan} onChange={(e)=>setbeyan(e.target.value)} className="inputmblg"></textarea>
                      
                   <Progress percent={progess} />
                    </div>

                   <div className={progess>0?"btnmblgzero":"btnmblg"}>
                        <div className={btnadd ? "btnmbzro" :"btnmb "} onClick={AddWasel}>اضافة</div>
                         <div className={btnadd ? "btnmb" :"btnmbzro "} onClick={NewAdd}>جديد</div>
                    <div className={!btnadd ? "btnmb btnupdate" :" btnmb btnupdate opt"}  onClick={UpdateWasel}>تعديل</div>
                    <div className={!btnadd ? "btnmb btndelete" :"btnmb btndelete opt"} onClick={deletewsel}>حذف</div>
                        <div className="btnmb btnprint" onClick={gotokshfhusab}>كشف حساب</div>
                       
                </div>
                      <div className="btnmbcancel" onClick={calncel}>الغاء</div>
                </div>

               


             </div>
{clickiconmenufolder_REDUCER ? (
                <div className="overfloeiconfoldermal" onClick={setoverflowmenu}></div>
            ) : (
                ""
            )}
            {showslideuser ? <SlideUsers clas="SlideUsers" /> : ""}
        </div>
    )
}

export default Malia


