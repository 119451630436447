import React, { useEffect, useState } from "react";
import { RiMenuFill } from "react-icons/ri";
import axios from "../../utils/API";
import SlideUsers from "../NewUserForm/SlideUsers";
import { actiontype } from "../../reducer/Reducer";
import { useStateValue } from "../../reducer/StateProvider";
import { message } from "antd";

const NotyUser = () => {

    const [
        {
            clickiconmenufolder_REDUCER

        },
        dispatch,
    ] = useStateValue();

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }
    
   // const iduser = localStorage.getItem("idusee");
    const idmk = localStorage.getItem("idmustakdem");

    //const [btnadd, setbtnadd] = useState(false);
    const [progess, setprogess] = useState(0);
    const [MSG] = useState("");
    const [showslideuser, setshowslideuser] = useState(false);
    const [username, setusername] = useState("");

 

    const [notyuser, setnotyuser] = useState([{}]);


    
    const showsleider = async () => {

        setshowslideuser(true);
        dispatch({
            type: actiontype.SET_OVERMENUE,
            items: true,
        });

    };

  

    const setoverflowmenu = () => {
        dispatch({
            type: actiontype.SET_OVERMENUE,
            items: false,

        });

        dispatch({
            type: actiontype.SET_SHOWREdate,
            items: false,
        });

        dispatch({
            type: actiontype.SET_SHOWADDFOLDERFORM,
            items: false,
        });
        setshowslideuser(false);
    }

    
    useEffect(() => {
        //setkey(idwsel);
        const usermustakem = async () => {
            const data = await axios.post(`users/${idmk}`);
            setusername(data.data.username);

            const datanoty = await axios.get(`NotyUser/${idmk}`);
            setnotyuser(datanoty.data[0].notify);


        }
        usermustakem(idmk);

    }, []);
    
    


    useEffect(() => {
        if (progess === 100) {
            setTimeout(() => {
                message.success(MSG);
                setprogess(0);
            }, 1000);
        }
    }, [progess]);
    


  return (
    <div>
      <div className="fixed">
        <div className="navbar">
          <div className="logo">
            <img
              src={window.location.origin + "/img/logo.png"}
              style={{ height: "2em" }}
              className="logoinuserform"
              alt="logo"
            />
          </div>
          <h3 style={{ color: "#FFF" }}>قسم الأشعارات</h3>
          <div className="iconright_malia">
            <div style={{ textAlign: "center" }}>
              <RiMenuFill
                onClick={showsleider}
                style={{ cursor: "pointer" }}
                size={25}
              />
              <div>قائمة</div>
            </div>
          </div>
        </div>

        <div className="header2" style={{ justifyContent: "center" }}>
          اشعارات المستخدم: {username}
              </div>
              
          </div>

          <div className="notify_User">
              {notyuser.reverse().map((res, i) => 
                  <div className="noty" key={i} >
                       <p >{res.tital}</p>
                      <p className="titlanoty">{res.ojb}</p>
                       <p>{res.red?"تم قرائتها":"لم يتم قرائتها "}</p>
                  <div>
                      <p>{formatDate(res.date)}</p>
                     
                  </div>
              </div>  
            )}
              

                  

          </div>

            
          
          {clickiconmenufolder_REDUCER ? (
                <div className="overfloeiconfoldermal" onClick={setoverflowmenu}></div>
            ) : (
                ""
            )}
          {showslideuser ? <SlideUsers clas="SlideUsers" /> : ""}
          
    </div>
  );
};

export default NotyUser;
