import React, { useState,useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./style.css";
import { BiLogOutCircle } from "react-icons/bi";
import { FaCalendarDay, FaUsersCog, FaUser } from "react-icons/fa";
import axios from "../../utils/API";

const NavDashBoard = () => {
  
  const history = useHistory();

  const [amaluser, setamaluser] = useState(0);

  const loguotuser = async () => {

    history.push("/HomeAdminManage", { from: "NavDashBoard" });
  };

  const settinguser = () => {
    history.push("/usersmange", { from: "NavDashBoard" });
  };

  const gotoamal = () => {
    history.push("/Amal", { from: "NavDashBoard" });
  }

  useEffect(() => {
    
    const usermustakem = async ()=>{

    const dataamal = await axios.get(`Amal`);
      if (dataamal.data[0].amal.length > 0)
      {
        
        const lenamal = dataamal.data[0].amal.filter(res => res.red === false).map(res => res)
        setamaluser(lenamal.length);
      }
      else {
        setamaluser(0);
      }
    }
    usermustakem();
  })


  const addnewuser = async () => {
  
      history.push("/addusers", { from: "Users" });
    
  };

  return (
    <div className="dashboard">
      <div className="dashboard_header">
        
      <div
          onClick={gotoamal}
          style={{ cursor: "pointer" ,position:"relative"}}
          className="dashboard_header_div link_header_dashboard"
        >
          <FaCalendarDay className="ml-1" size={15} />
        ج الاعمال
          <div className="notiusermain">{amaluser}</div>

        </div>

        <div
          onClick={addnewuser}
          style={{ cursor: "pointer" }}
          className="dashboard_header_div link_header_dashboard"
        >
          <FaUser className="ml-1" size={15} />
          اضافة مستخدم

        </div>

        <div
          onClick={loguotuser}
          style={{ cursor: "pointer" }}
          className="dashboard_header_div link_header_dashboard ahadred"
        >
          <BiLogOutCircle className="ml-1 " size={25} />الصفحة الرئيسية</div>

        {/*
        <div
          onClick={settinguser}
          style={{ cursor: "pointer" }}
          className="dashboard_header_div link_header_dashboard"
        >
          <FaUsersCog className="ml-1 " size={25} />
          المستخدمين
        </div>
        */}
       

      </div>
    </div>
  );
};

export default NavDashBoard;
