import React ,{useState,useEffect} from 'react'
import { Result, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import Main from "../../Pages/Main";
import { BrowserRouter as Router, Switch, Route ,Redirect} from "react-router-dom";


const NoInternet = () => {
const history = useHistory();

  const [isOnline, setNetwork] = useState(window.navigator.onLine);

useEffect(() => {
window.addEventListener("offline", 
        () => setNetwork(window.navigator.onLine)
      );
window.addEventListener("online", 
        () => setNetwork(window.navigator.onLine)
      );
},[isOnline]);


   const   GoToHome = () => {
    history.push("/", { from: "NoInternet" });
     history.replace("/login");
     window.location.reload();
   }
    return (
       <div>
      {isOnline?
        
<Router>
     
    
        <Switch>
        <Redirect  push   to={{
    pathname: "/login",
   
    state: { referrer: "/500" }
  }} />
    
     
    </Switch>
      </Router>
      :
      <Result
    status="500"
    title="500"
    subTitle="يبدو انة لا يوجد انترنيت في جهازك يرجى التأكد من اتصال الانترنيت وتحديث الصفحة"
    extra={<Button type="primary" onClick={GoToHome}>الذهاب الى صفحة تسجيل الدخول</Button>}
  />


      }
       </div>
    )
}

export default NoInternet
