import React, { useEffect, useState } from "react";
import NavDashBoard from ".././NavDashBoard";
import axios from "../../utils/API";
import { useHistory } from "react-router-dom";
import { message, Progress } from "antd";
const AddUsers = () => {
  const idusers = Math.floor(Math.random() * 178664214);
  const [iduser, setiduser] = useState(idusers);
  const [username, setusername] = useState("");
  const [passwordusers, setpasswordusers] = useState("");
  const [phone, setphone] = useState("");

  const [rqmadbara, setrqmadbara] = useState("");
  const [asemmshro, setasemmshro] = useState("");

  const [address, setaddress] = useState("");
  const [stateuser, setastateuser] = useState("فعال");
  const [roleuser, setroleuser] = useState("مستخدم");
  const [progess, setprogess] = useState(0);
  const userid = localStorage.getItem("idusee");
  const history = useHistory();
  const addnewuser = async (event) => {
    event.persist();
    const userone = await axios.post(`users/${userid}`);
    if (userone.data.permistoin_users[0].adduser === "لا") {
      message.error("لا تمتلك صلاحية اضافة مستخدم");
    } else {
      if (username === "") {
        message.error("يرجى ادخال اسم المستخدم");
      } else if (iduser === "") {
        message.error("يرجى ادخال رقم المستخدم");
      } else if (passwordusers === "") {
        message.error("يرجى ادخال كلمة مرور المستخدم");
      } else {
        //
        setusername("");
        setpasswordusers("");
        setphone("");
        setaddress("");
        setrqmadbara("");
        setasemmshro("");
        const idusers = Math.floor(Math.random() * 178664214);
        setiduser(idusers);
        axios.post(
          "users",
          {
            iduser: iduser,
            username: username,
            password: passwordusers,
            phone: phone,
            address: address,
            state: stateuser,
            roleuser: roleuser,
            rqmadbara: rqmadbara,
            asemmshro: asemmshro,

          },
          {
            onUploadProgress: (progressEvent) =>
              //console.log(Math.round((progressEvent.loaded * 100) / progressEvent.total));
              setprogess(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              ),
          }
        );
      }
    }
  };
  const [loadingpage, setloadingpage] = useState(true);
  const puser = async () => {
    const userone = await axios.post(`users/${userid}`);
    if (userone.data.role === "مستخدم") {
      history.push("/HomeUserManage", { from: "ProtectedRoute" });
      setloadingpage(true);
    } else {
      setloadingpage(false);
    }
  };

   const settinguser = () => {
    history.push("/usersmange", { from: "NavDashBoard" });
  };

  puser();

  useEffect(() => {
    if (progess === 100) {
      setTimeout(() => {
        message.success("تم اضافة مستخدم جديد");
        setprogess(0);
      }, 1000);
    }
  }, [progess]);
  return (
    <div>
      {loadingpage && (
        <div>
          <div className="overflowimg"></div>
          <img
            src={window.location.origin + "/img/loding.gif"}
            className="img_loding"
            alt="logo"
          />
        </div>
      )}

      <NavDashBoard />
      <div className="users_header">
        <div>اضافة مستخدم جديد</div>
        <div onClick={settinguser} className="btn_amal_user active">العودة الى المستخدمين</div>
      </div>
      
      <div className="container addusernew">
        <div className="row">
          <div className="col-sm-12 col-md-6 mt-3">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">اسم المستخدم</span>
              </div>
              <input
                type="text"
                aria-label="First name"
                className="form-control"
                value={username}
                onChange={(e) => setusername(e.target.value)}
              />
            </div>
          </div>

          <div className="col-sm-12 col-md-6 mt-3">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">اسم المشروع</span>
              </div>
              <input
                type="text"
                aria-label="First name"
                className="form-control"
                value={asemmshro}
                onChange={(e) => setasemmshro(e.target.value)}
              />
            </div>
          </div>

          <div className="col-sm-12 col-md-6 mt-3">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">رقم المستخدم</span>
              </div>
              <input
                type="number"
                aria-label="First name"
                className="form-control"
                value={iduser}
                onChange={(e) => setiduser(e.target.value)}
              />
            </div>
          </div>

          <div className="col-sm-12 col-md-6 mt-3">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">كلمة المرور</span>
              </div>
              <input
                type="text"
                aria-label="First name"
                className="form-control"
                value={passwordusers}
                onChange={(e) => setpasswordusers(e.target.value)}
              />
            </div>
          </div>

          <div className="col-sm-12 col-md-6 mt-3">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">رقم الهاتف</span>
              </div>
              <input
                type="number"
                aria-label="First name"
                className="form-control"
                value={phone}
                onChange={(e) => setphone(e.target.value)}
              />
            </div>
          </div>

          <div className="col-sm-12 col-md-6 mt-3">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">رقم الاضبارة</span>
              </div>
              <input
                type="text"
                aria-label="First name"
                className="form-control"
                value={rqmadbara}
                onChange={(e) => setrqmadbara(e.target.value)}
              />
            </div>
          </div>
          

          <div className="col-sm-12 col-md-6 mt-3">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">العنوان</span>
              </div>
              <input
                type="text"
                aria-label="First name"
                className="form-control"
                value={address}
                onChange={(e) => setaddress(e.target.value)}
              />
            </div>
          </div>

          <div className="col-sm-12 col-md-3 mt-3">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">نوع المستخدم</span>
              </div>
              <select
                className="form-control p-0"
                value={roleuser}
                onChange={(e) => setroleuser(e.target.value)}
              >
                <option>مستخدم</option>
                <option>مدير نظام</option>
              </select>
            </div>
          </div>

          <div className="col-sm-12 col-md-3 mt-3">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">الحالة</span>
              </div>
              <select
                className="form-control p-0"
                value={stateuser}
                onChange={(e) => setastateuser(e.target.value)}
              >
                <option>فعال</option>
                <option>متوقف</option>
              </select>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 mt-3">
            <button onClick={addnewuser} className="btn btn-primary" disabled={progess>0?true:false}>
              اضافة
            </button>
            <Progress percent={progess} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUsers;
