import React, { useEffect, useState } from "react";
import "./style.css";
import axios from "../../utils/API";
import IconFolder from "./IconFolder";

//icon
import {
  RiArrowLeftSLine,
  RiFile2Fill,
  RiFileUploadLine,
  
  RiMenuFill,
  RiSearchLine,
} from "react-icons/ri";

import { TiHome } from "react-icons/ti";
import { actiontype } from "../../reducer/Reducer";
import { useStateValue } from "../../reducer/StateProvider";
import Rename from "./Rename";
import AddNewFolder from "./AddNewFolder";
import { FaFileAlt } from "react-icons/fa";
import {

  AiOutlineFileImage,
  AiOutlineFileJpg,
} from "react-icons/ai";
import { useHistory, useLocation } from "react-router-dom";
import { VscFilePdf } from "react-icons/vsc";
import SlideUsers from "./SlideUsers";
import { message } from "antd";
import { Input } from 'antd';




const Files = ({folderid,foldername}) => {

    const userid = localStorage.getItem("idmustakdem");
  const useridAuth = localStorage.getItem("idusee");
  const tokens = localStorage.getItem("token");
  const baseURLImg =
    `https://seadimahbobaapi.xyz/ftp/${tokens}/uploads/`;

  const history = useHistory();
  const location = useLocation();
  const myparam = location.state.params;
  const parmnamefolder = location.state.parmnamefolder;
  
  const [serchin, setserchin] = useState(false);

  const [datauser, setdatauser] = useState([]);
  const [namefolder, setnamefolder] = useState(parmnamefolder);
  const [showslideuser, setshowslideuser] = useState(false);

  const [idfolder, setidfolder] = useState(myparam);
  const [classactive, setclassactive] = useState(false);
  //const [showaddfolderform,setshowaddfolderform] = useState(false);
  const [
    {
      clickiconmenufolder_REDUCER,
      showrenameform,
      showfolderform,
      afterdeletfile,
    },
    dispatch,
  ] = useStateValue();
  const setoverflowmenu = () => {
    dispatch({
      type: actiontype.SET_OVERMENUE,
      items: false,
    });

    dispatch({
      type: actiontype.SET_SHOWRENAME,
      items: false,
    });

    dispatch({
      type: actiontype.SET_SHOWADDFOLDERFORM,
      items: false,
    });
    setshowslideuser(false);
  };

  const [filefolder, setfilefolder] = useState([]);
  //  const [DF, seDF] = useState(false);
  try {
    useEffect(() => {
      try {
        const fetchdata = async (userid, idfolder) => {
          const userr = await axios.post(`folder/${userid}`);
          setdatauser(userr.data.folder);
          if (afterdeletfile === true) {
            datauser
              .filter((res) => res.id === idfolder)
              .map((rs) => setfilefolder(rs.files));
          }

          if (filefolder.length === 0) {
            dispatch({
              type: actiontype.SET_UPDATEDATAAFTERDELETEFILE,
              items: false,
            });
          }
        };

        fetchdata(userid, idfolder);
      } catch (error) {
        console.log("error");
      }
    }, [
      datauser,
      userid,
      idfolder,
      afterdeletfile,
      dispatch,
      filefolder.length,
    ]);
  } catch (error) {
    console.log("error");
  }
/*
  const ShowِAddNewFolder =async () => {
    const userone = await axios.post(`users/${useridAuth}`);
    if (userone.data.permistoin_users[0].addfolder==="لا") {
message.error("لا تمتلك صلاحية اضافة مجلد")
}
else {
    dispatch({
      type: actiontype.SET_OVERMENUE,
      items: true,
    });

    dispatch({
      type: actiontype.SET_SHOWADDFOLDERFORM,
      items: true,
    });
  }
  };
  */
  //const [filefolderM, setfilefolderM] = useState([]);

  const EnterToFolder = (e, contfolder, contfile, folderidfromdata) => {
    
    /*datauser
      .filter((res) => res.id === folderidfromdata)
      .map((rs) =>
        rs.files.length === 0
          ? setfilefolder([{ originalname: "" }])
          : setfilefolder(rs.files)
      );
*/
    // datauser.filter((res) => res.id === folderidfromdata).map((rs) =>console.log(rs.files) );
    setnamefolder(e);
    setidfolder(folderidfromdata);
    setclassactive(true);
    //getnamefolder();
  };

  useEffect(() => {
      try
      {

        if(!serchin){
    EnterToFolder(parmnamefolder,0,0,myparam);
    datauser
      .filter((res) => res.id === myparam)
      .map((rs) =>
        rs.files.length === 0
          ? setfilefolder([{ originalname: "" }])
          : setfilefolder(rs.files)
      );
        }
    } catch (error) {
        console.log("error");
      }
  },[datauser,myparam,parmnamefolder,serchin])
  //console.log(filefolder);

 /* const EnterToFolderM = (e, contfolder, contfile, folderidfromdata) => {
    setnamefolder(e);
    setidfolder(folderidfromdata);
    setclassactive(true);
  };*/

  //console.log(filefolder);

  const gotohome =async () => {
    /*setnamefolder("");
    setfilefolder([]);
    dispatch({
      type: actiontype.SET_UPDATEDATAAFTERDELETEFILE,
      items: false,
    });*/
    //const path = location.state.parmpath;
    //history.push(path, { from: "/Files" });
    const userone = await axios.post(`users/${useridAuth}`);
    if (userone.data.role === "مدير نظام") {
        history.push("/FilesUsers", { from: "/Files" });
      } else {
        history.push("/Mustakdem", { from: "/Files" });
      }

   
  

  };

  const GoToUplodeFile = async () => {
    const userone = await axios.post(`users/${useridAuth}`);
    if (userone.data.permistoin_users[0].add==="لا") {
message.error("لا تمتلك صلاحية اضافة الملف")
}

else {
    history.push("/UplodeFile", { from: "index" });
    localStorage.setItem("idfolder", idfolder);
    localStorage.setItem("namefolder", namefolder);
}
  };

  const showsleider = async() => {
    
    
    setshowslideuser(true);
    dispatch({
      type: actiontype.SET_OVERMENUE,
      items: true,
    });
  
  };

  /*Search input */


  const { Search } = Input;

  const [serch, setserch] = useState(false);
  const onSearch = value => {
    if (value==="") {
        setserchin(false);
      }
      else{
      
    setserchin(true);
  const getserch =  filefolder.filter(rs=>rs.originalname.includes(value)).map(res=>res)
   // console.log(getserch)
    setfilefolder(getserch)
      }
  };


  const closeserch =()=>{
    setserch(false);
    setserchin(false);
  }

/*End Search input */

    return (
        <div>
            {/*Start navbar*/}
      <div className="navbar">
          {serch?
          <div className="formserch">
              
              <div style={{flexBasis:"85%",direction:"rtl"}}><Search  allowClear  placeholder="يرجى ادخال اسم الملف"  onSearch={onSearch} enterButton /></div>
              <div className="closeserch" onClick={closeserch}>X</div>
              </div>
:
        <>
        <div className="logo">
          <img
            src={window.location.origin + "/img/logo.png"}
            style={{ height: "2em" }}
            className="logoinuserform"
            alt="logo"
          />
        </div>

        <div className="iconright">
         
          
          
          <div style={{textAlign:"center"}}>
          <RiSearchLine style={{ cursor: "pointer" }} size={25} onClick={()=>setserch(true)}/>
          <div>بحث</div>
          </div>

          <div style={{textAlign:"center"}}>
        
        <RiFileUploadLine
              style={{ cursor: "pointer" }}
              size={25}
              onClick={GoToUplodeFile}
            />
          
          <div>ملف</div>
          </div>

          <div style={{textAlign:"center"}}>
        
          <RiMenuFill
            onClick={showsleider}
            style={{ cursor: "pointer" }}
            size={25}
          />
          <div>قائمة</div>
          </div>


        </div>
        </>
}
      </div>

      {/*End navbar*/}

      {/*Start Headr*/}
      <div className="header">
        <TiHome style={{ cursor: "pointer" }} size={25} onClick={gotohome} />
        {namefolder !== "" ? (
          <div>
            <RiArrowLeftSLine size={15} className="h4wrap" /> {namefolder}{" "}
          </div>
        ) : (
          <div>
            <RiArrowLeftSLine size={15} /> ملفاتي{" "}
          </div>
        )}

        {filefolder.length > 0 ? (
          <div
            onClick={gotohome}
            style={{
              cursor: "pointer",
              marginRight: "auto",
              marginLeft: 10,
              color: "#000",
            }}
          >
            رجوع
          </div>
        ) : (
          ""
        )}
      </div>

      {/*End Headr*/}

      
      {/*Start Content Folder And File*/}

      {clickiconmenufolder_REDUCER ? (
        <div className="overfloeiconfolder" onClick={setoverflowmenu}></div>
      ) : (
        ""
      )}

      {filefolder.length !== 0
        ? filefolder.map((res, i) =>
            res.originalname === "" ? (
              <div key={i * 55544} style={{ textAlign: "center", margin: 100 }}>
                <FaFileAlt
                  style={{ textAlign: "center", margin: 10 }}
                  size={50}
                />{" "}
                <h1>لا توجد ملفات</h1>
                <p>يرجى اضافة ملف من خلال القائمة الظاهرة في الاعلى</p>
                <p style={{ textAlign: "center", marginTop: 5 }}>
                  او الضغط هنا{" "}
                  <RiFileUploadLine
                    style={{ cursor: "pointer" }}
                    size={40}
                    onClick={GoToUplodeFile}
                  />
                </p>
              </div>
            ) : (
              <div
                key={i}
                className={
                  classactive ? "ContentFolderAndFile" : "ContentFolderAndFile"
                }
              >
                {res.mimetype === "image/jpeg" ? (
                  <AiOutlineFileJpg
                    className="svgicon"
                    size={25}
                    fill="#2792bf"
                  />
                ) : res.mimetype === "image/png" ? (
                  <AiOutlineFileImage
                    className="svgicon"
                    size={25}
                    fill="#2792bf"
                  />
                ) : res.mimetype === "application/pdf" ? (
                  <VscFilePdf className="svgicon" size={25} fill="#2792bf" />
                ) : (
                  <RiFile2Fill className="svgicon" size={25} fill="#2792bf" />
                )}

                <div className="titleFolder">
                  <a
                    href={`${baseURLImg}/${res.filename}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <h4 className="h4wrap">{res.originalname}</h4>
                  </a>

                  <div className="folderdetails"></div>
                </div>

                <div>
                  {" "}
                  <IconFolder
                    namefolder={res.originalname}
                    folderid={idfolder}
                    folder={true}
                    typefile={res.mimetype}
                    filname={res.filename}
                  />
                </div>
              </div>
            )
          )
        : ""}

      {showrenameform ? (
        <Rename namefolder={namefolder} idfolder={idfolder} />
      ) : (
        ""
      )}

      {showfolderform ? <AddNewFolder /> : ""}

      {showslideuser ? <SlideUsers clas="SlideUsers" /> : ""}

      {/*End Content Folder And File*/}

        </div>
    )
}

export default Files
