import firebase from 'firebase'

   var firebaseConfig = {
    apiKey: "AIzaSyDXxH2h9y1NE2pXMNaVpYgWqpqDYcKttNc",
    authDomain: "sademhboba.firebaseapp.com",
    projectId: "sademhboba",
    storageBucket: "sademhboba.appspot.com",
    messagingSenderId: "80871090309",
    appId: "1:80871090309:web:03a39aae185bc12904a1ec"
};
    // Initialize Firebase
firebase.initializeApp(firebaseConfig);
  



export default firebase
