import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import axios from ".././utils/API";

const ProtectedRoute = ({ Component }) => {
    // const isAuthenticated = localStorage.getItem('token');

    const userid = localStorage.getItem("idusee");
    const LOGINUSER = localStorage.getItem("LOGINUSER");
    const history = useHistory();

    const [isAuthenticated, setisAuthenticated] = useState(true);

    try {
        useEffect(() => {
            try {
                const getperuser = async(userid) => {
                    await axios.post(`users/${userid}`);
                    if (LOGINUSER === null) {
                        setisAuthenticated(false);
                        history.push("/login", { from: "ProtectedRoute" });
                    }

                    if (userid === null) {
                        setisAuthenticated(false);
                        history.push("/login", { from: "ProtectedRoute" });
                    }
                };

                getperuser(userid);
            } catch (error) {
                console.log("");
            }
        }, [userid, LOGINUSER, history]);
    } catch (error) {}

    return isAuthenticated ? ( <
        Component / >
    ) : ( <
        Redirect to = {
            { pathname: "/login" } }
        />
    );
};

export default ProtectedRoute;