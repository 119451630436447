import React from 'react'
import { Result } from 'antd';


const ResultS = () => {

 

    return (
      <div>

        <Result
        status="success"
        title="تم عملية رفع الملفات بنجاح"
        subTitle="يمكنك رفع ملفات اخرى من خلال الضغط على رفع ملفات اخري او الرجوع للصفحة الرئيسية من خلال الضغط على الرجوع الى الصفحة الرئيسية"
        extra={[
         
        ]}
      />
      </div>
    )
}

export default ResultS
