
import {  message } from 'antd';


const key = 'updatable';
const key2 = 'updatable1';

const openMessage = () => {
    message.loading({ content: '...تحميل', key });
    setTimeout(() => {
      message.success({ content: 'تمت العملية بنجاح', key, duration: 2 });
    }, 1000);
  };


  export const Wrongmessage = ({msg}) => {
    
    setTimeout(() => {
      message.warn({ content:"يرجى ادخال اسم المجلد", key2, duration: 2 });
    }, 1000);
  };

  export const WrongFilesMsg = () => {
    
    setTimeout(() => {
      message.warn({ content:"يرجى تحديد ملف او اكثر ", key2, duration: 2 });
    }, 1000);
  };

export default openMessage
