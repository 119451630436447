import React, { useEffect, useState } from "react";
import { RiMenuFill } from "react-icons/ri";
import axios from "../../utils/API";
import SlideUsers from "../NewUserForm/SlideUsers";
import { actiontype } from "../../reducer/Reducer";
import { useStateValue } from "../../reducer/StateProvider";
import { message, Progress } from "antd";
import { v4 as uuidv4 } from 'uuid';
import "./Style.css";
import { useHistory } from "react-router";
import { AiFillBell, AiFillSetting } from "react-icons/ai";

const Amal = () => {



    
       const [
        {
            clickiconmenufolder_REDUCER

        },
        dispatch,
    ] = useStateValue();

         

    const iduser = localStorage.getItem("idusee");
    const idmk = localStorage.getItem("idmustakdem");

     const history = useHistory();
  const puser = async () => {
    const userone = await axios.post(`users/${iduser}`);
    if (userone.data.role === "مستخدم") {
      history.push("/HomeUserManage", { from: "ProtectedRoute" });
    } else {
    }
  };
  puser();

    const [btnadd, setbtnadd] = useState(false);
    const [btnactive, setbtnactive] = useState(false);
    const [btnactiveqermktmla, setbtnactiveqermktmla] = useState(true);

    

    const [progess, setprogess] = useState(0);
    const [MSG, setMSG] = useState("");
    const [showslideuser, setshowslideuser] = useState(false);
    const [username, setusername] = useState("");

    const [titalnoty, settitalnoty] = useState("");
    const [opjnoty, setopjnoty] = useState("");
    const [key, setkey] = useState(uuidv4());
    
    const showsleider = async () => {

        setshowslideuser(true);
        dispatch({
            type: actiontype.SET_OVERMENUE,
            items: true,
        });

    };

    function formatDate(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear(),
          hours = d.getHours(),
          ment = d.getMinutes();
  
      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;
     const hm = hours + ":" + ment;
      return   [year, month, day].join('-');
  }
  
  

      const setoverflowmenu = () => {
        dispatch({
            type: actiontype.SET_OVERMENUE,
            items: false,

        });

        dispatch({
            type: actiontype.SET_SHOWREdate,
            items: false,
        });

        dispatch({
            type: actiontype.SET_SHOWADDFOLDERFORM,
            items: false,
        });
        setshowslideuser(false);
    }

    
    useEffect(() => {
        //setkey(idwsel);
        const usermustakem = async () => {
            const data = await axios.post(`users/${idmk}`);
            setusername(data.data.username);

        }
        usermustakem(idmk);

    }, [idmk]);


      useEffect(() => {
    if (progess === 100) {
      setTimeout(() => {
         message.success(MSG);
        setprogess(0);
      }, 1000);
    }
      }, [progess]);
    
    const NewAdd = () => {
        setkey(uuidv4());
      setbtnadd(false)
        settitalnoty("");
        setopjnoty("");
 
    }

  



    const [dataamalall, setdataamalall] = useState([])
    const [dataamal,setdataamal] =useState([])

    const getdataamalupdate = async () => {
      const resdata = await axios.get("/Amal")
//      setdataamal(resdata.data[0].amal)
      setdataamalall( resdata.data[0].amal
        .sort((a, b) => b.nameuser.localeCompare(a.nameuser))
        .filter(ress => ress.red===true)
        .map(res => res))
     }

    const getdataamal = async () => {
      const resdata = await axios.get("/Amal")
    //  setdataamal(resdata.data[0].amal)
      setdataamalall( resdata.data[0].amal
        .sort((a, b) => b.nameuser.localeCompare(a.nameuser))
        .filter(ress => !ress.red)
        .map(res => res))
     }

             useEffect(() => {
             
              if (btnactiveqermktmla) {
                getdataamal();
              }else{
                getdataamalupdate();
              }
            
              
            })

              
            
            
           
            

    const getmktmla = () => {
        setbtnactive(true);
        setbtnactiveqermktmla(false);
       
        getdataamalupdate();

        
    }

     const getqermktmla = () => {
         setbtnactiveqermktmla(true);
         setbtnactive(false);
         getdataamal();
    }

    
    useEffect(() => {
      setTimeout(() => {
        getqermktmla();
      }, 500);
    }, [dataamal])

   
    
    
 
    const updateamal=(keamal)=>{
      axios.put("/Amal",{key:keamal,red:true},
      {
          onUploadProgress: (progressEvent) =>
              //console.log(Math.round((progressEvent.loaded * 100) / progressEvent.total));
              setprogess(
                  Math.round((progressEvent.loaded * 100) / progressEvent.total)
              ),
      })

      getqermktmla();
      setMSG("تم اكمال هذا العمل");
      //getdataamal();

     
    }

    

    const updateamalqer=(keamal)=>{
       axios.put("/Amal",{key:keamal,red:false},
      {
          onUploadProgress: (progressEvent) =>
              //console.log(Math.round((progressEvent.loaded * 100) / progressEvent.total));
              setprogess(
                  Math.round((progressEvent.loaded * 100) / progressEvent.total)
              ),
      })

      getmktmla();
      setMSG("تم تعديل هذا العمل كغير متكمل");

    // getdataamalupdate();
     

    }


    const gotousermange =async () => {
        const userone = await axios.post(`users/${iduser}`);
    if (userone.data.role === "مدير نظام") {
      history.push("/usersmange", { from: "ProtectedRoute" });
     
    } else {
      history.push("/", { from: "ProtectedRoute" });
    }
    }
  const getonewamal =async () => {
        const userone = await axios.post(`users/${iduser}`);
    if (userone.data.role === "مدير نظام") {
      history.push("/NewAmal", { from: "ProtectedRoute" });
     
    } else {
      history.push("/", { from: "ProtectedRoute" });
    }
    }
    
    const GETFILES = async (iduserss) => {
      // localStorage.setItem("idusee", idusers);
       localStorage.setItem("idmustakdem", iduserss);
       history.push("/FilesUsers", { from: "Amal" });
       }

  return (
    <div>
      <div className="fixed">
        <div className="navbar">
        
          <h3 style={{ color: "#FFF" }}>جدول الاعمال</h3>
         <div className="btn_amal_user active" onClick={gotousermange}>ادارة المستخدمين</div>
        </div>

        <div className="header2" style={{ justifyContent: "space-between" }}>
                  الاعمال اليومية
                   <div style={{ display: "flex" }}>
                   <div className="btn_amal_new mr-2" onClick={getonewamal}>جديد</div>   
                  <div className={btnactiveqermktmla ? "btn_amal_user active mr-2" : "btn_amal_user mr-2"} onClick={getqermktmla}>الغير مكتملة</div>
                      <div className={btnactive ? "btn_amal_user active mr-2" : "btn_amal_user mr-2"} onClick={getmktmla}>المكتملة</div>
                      </div>
        </div>
      </div>
      <div className="amal_content">
        {dataamalall
                  .sort((a, b) => b.nameuser.localeCompare(a.nameuser))  
                  .map((res, i) => (
              
                      <div className="amal_user" key={i}>
                          <div className="touser">
                             <div>{res.nameuser}</div>
              <div className="gotouser" onClick={()=>GETFILES(res.iduser)}>ملفات المستخدم <AiFillSetting/></div>
                          </div>
                              
             
              <div className="amal_user_content">
                <div  style={{width: "50%",textAlign:"right"}}>{res.note}</div>
                <div>{formatDate(res.date)}</div>
                {!res.red ? (
                  <button  className="btn_amal_user" onClick={(e)=>updateamal(res.key)}>اكمال</button>
                ) : (
                  <div className="btn_amal_user btn_amal_user_qer" onClick={(e)=>updateamalqer(res.key)}>
                    غير مكتمل
                  </div>
                )}
              </div>
            </div>
          ))}
      </div>

      {clickiconmenufolder_REDUCER ? (
        <div className="overfloeiconfoldermal" onClick={setoverflowmenu}></div>
      ) : (
        ""
      )}
      {showslideuser ? <SlideUsers clas="SlideUsers" /> : ""}
    </div>
  );
};

export default Amal;
