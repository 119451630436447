import React, { useEffect, useState } from 'react';
import { RiMenuFill } from 'react-icons/ri';
import './style.css';
import { actiontype } from "../../reducer/Reducer";
import { useStateValue } from "../../reducer/StateProvider";
import SlideUsers from '../NewUserForm/SlideUsers';
import axios from "../../utils/API";
import {  InputNumber, message, Table, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";


const MaliaUser = () => {


     const history = useHistory();
    const iduser = localStorage.getItem("idusee");
    const idmk = localStorage.getItem("idmustakdem");

    const [managerusers, setmanagerusers] = useState(false);


    const [username, setusername] = useState("");
    const [showslideuser, setshowslideuser] = useState(false);
   

 function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

function formatDate2(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + (d.getDate()-5),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
    }
    
 



    const [datefrom, setdatefrom] = useState(formatDate2(new Date()));
    const [dateto, setdateto] = useState(formatDate(new Date()));

    
    const [
        {
            clickiconmenufolder_REDUCER,
dtkshf
        },
        dispatch,
    ] = useStateValue();

    const setoverflowmenu = () => {
        dispatch({
            type: actiontype.SET_OVERMENUE,
            items: false,

        });

        dispatch({
            type: actiontype.SET_SHOWREdate,
            items: false,
        });

        dispatch({
            type: actiontype.SET_SHOWADDFOLDERFORM,
            items: false,
        });
        setshowslideuser(false);
    };
    const showsleider = async () => {

        setshowslideuser(true);
        dispatch({
            type: actiontype.SET_OVERMENUE,
            items: true,
        });

    };


    const gotomalia = async (key,date,mden,daen,amla,byean) => {
        localStorage.setItem("idwsel", key);
        localStorage.setItem("date", date);

        if (mden>0) {
            localStorage.setItem("mblgswel", mden);
            localStorage.setItem("addmblg", "add");
        }
        if (daen>0) {
            localStorage.setItem("mblgswel", daen);
            localStorage.setItem("addmblg", "tasded");
        }

         localStorage.setItem("amla", amla);
        localStorage.setItem("byean", byean);

        const userone = await axios.post(`users/${iduser}`);
          if (userone.data.role === "مدير نظام") {
        history.push("/MaliaUpdate", { from: "MaliaUser" });
    } else {
       history.push("/MaliaUser", { from: "MaliaUser" });
    }

        
       
    }
    useEffect(() => {
        const usermustakem = async () => {
            const data = await axios.post(`users/${idmk}`);
            setusername(data.data.username);

        }
        usermustakem(idmk);

    }, [idmk]);


    const { Text } = Typography;

    const columns = [
        {
            title: 'التاريخ',
            dataIndex: 'date',
            width: 120,
            align: 'center',
        },
        {
            title: 'مدين/طلب',
            dataIndex: 'mden',
            width: 120,
            align: 'center',


            className: 'column-money thtabls',
             render: (text, record) => (
      
       
         <InputNumber
          style={{width: "100%",border:"none"}}
                            value={record.mden} 
                          
            bordered={false}
            readOnly
      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
    parser={value => value.replace(/\D/g, "")}
    />
     
    ),
        },
        {
            title: 'دائن/تسديد',
            dataIndex: 'daen',
            width: 150,
            align: 'center',
            className: 'column-money',
            
    render: (text, record) => (
      
       
         <InputNumber
          style={{width: "100%",border:"none"}}
                            value={record.daen} 
                          
            bordered={false}
            readOnly
      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
    parser={value => value.replace(/\D/g, "")}
    />
     
    ),
        },
        
        {
            title: 'البيان',
            dataIndex: 'byean',
            align: 'center',



        },
         {
    title: 'الوصل',
             key: 'action',
      align: 'center',
       width: 120,
    render: (text, record) => (
      
        <div className="divwsel" onClick={() => gotomalia(record.key ,record.date,record.mden,record.daen,record.amla,record.byean)}>الوصل</div>
        
     
    ),
  },
    ];



    const [dt,setdt]=useState([{key: '0',
            date: '0',
            mden: 0,
            daen: 0,
            amla: "0",
            byean: "0"}]);
    useEffect(() => {
       
        const fetchdatamalia = async (idmk) => {
            const res = await axios.get(`Malia/${idmk}`);

           
            setdt(res.data[0].malia.
            sort((date1, date2) => {
    date1 = date1.date.split('-').reverse().join('-');
    date2 = date2.date.split('-').reverse().join('-');
    if (date1 < date2) {
        return -1;
    } else if (date1 > date2) {
        return 1;
    } else {
        return 0;
    }
})
           
           
            )

            
           
        }
        //fetchdatamalia(idmk);
        serchkshfuser();
    },[idmk])


   

     
  
    const serchkshfuser = async () => {
        if (datefrom === "" || dateto === "") {
            message.error("يرجى تحديد الفترة من تاريخ الى تاريخ")
        }

        else {
            //console.log(datefrom + " " + dateto);
            const res = await axios.get(`Malia/${idmk}`);
           
            //setdt(res.data[0].malia)
          
            const dtt = [];

            
            const reducer = (accumulator, currentValue) => accumulator + currentValue;
            const reducerdate = (a, b) => a.date > b.date ? a : b;
   
            
   

            const datasearchmden = res.data[0].malia.filter(res => res.date < datefrom && res.amla === "دينار عراقي").map(res => parseFloat(res.mden)).reduce(reducer, 0);

            const datasearchdaen = res.data[0].malia.filter(res => res.date < datefrom && res.amla === "دينار عراقي").map(res => parseFloat(res.daen)).reduce(reducer, 0);
            

            /*
            const datasearchmdendol = res.data[0].malia.filter(res => res.date < datefrom && res.amla === "دولار").map(res => parseFloat(res.mden)).reduce(reducer, 0);
             
            const datasearchdaendol = res.data[0].malia.filter(res => res.date < datefrom && res.amla === "دولار").map(res => parseFloat(res.daen)).reduce(reducer, 0);

            const dateserchmaxdatedol = res.data[0].malia.filter(res => res.date < datefrom && res.amla === "دولار").map(res => res);  
            var maxdol = dateserchmaxdatedol.reduce(reducerdate,0);
           */

             const dateserchmaxdateddinariq = res.data[0].malia.filter(res => res.date < datefrom && res.amla === "دينار عراقي").map(res => res);  
            var maxdinar = dateserchmaxdateddinariq.reduce(reducerdate, 0);
            
            if (maxdinar.date) {
               
                 //console.log(maxdinar.date);
            }
           
           
             
           

            if (datasearchmden >0|| datasearchdaen >0) {
                  dtt.push({key: '0',
            date:maxdinar.date?maxdinar.date:datefrom,
            mden: datasearchmden,
            daen: datasearchdaen,
            amla: "دينار عراقي",
                byean: "رصيد سابق"
            
            })
        }
         
        

         // setdt(datasearch);

           
            const datasearch = res.data[0].malia.filter(res => res.date >= datefrom && res.date <= dateto).map(res => res)
           
             dtt.push(...datasearch);
           

            setdt(dtt.sort((date1, date2) => {
    date1 = date1.date.split('-').reverse().join('-');
    date2 = date2.date.split('-').reverse().join('-');
    if (date1 < date2) {
        return -1;
    } else if (date1 > date2) {
        return 1;
    } else {
        return 0;
    }
}))




            localStorage.setItem("datefrom", datefrom);
            localStorage.setItem("dateto", dateto);

  dispatch({
            type: actiontype.SET_DTKSHF,
            items: dtt.sort((date1, date2) => {
    date1 = date1.date.split('-').reverse().join('-');
    date2 = date2.date.split('-').reverse().join('-');
    if (date1 < date2) {
        return -1;
    } else if (date1 > date2) {
        return 1;
    } else {
        return 0;
    }
})
        });




            
           // console.log(datasearch);

        }
    }


    
    const userone = async (idus) => {
        const data = await axios.post(`users/${idus}`);
      
        if (data.data.role === "مدير نظام") {
          setmanagerusers(true);
          
        } else {
          setmanagerusers(false);
        
        }
      };
    
    
    
      useEffect(() => {
        userone(iduser);
    
      }, [iduser]);
      
    const gotoprintkshf = () => {
        
        if (dtkshf.length === 0) {
            message.error("يرجى عمل بحث عن الكشف اولاً")
        }
        else {
             history.push("/PrintKshf");
        }
       

    }


    return (
        <div>

            <div className="fixed">


                <div className="navbar">

                    <div className="logo">
                        <img
                            src={window.location.origin + "/img/logo.png"}
                            style={{ height: "2em" }}
                            className="logoinuserform"
                            alt="logo"
                        />
                    </div>
                    <h3 style={{ color: "#FFF" }}>قسم المالية</h3>
                    <div className="iconright_malia">

                        <div style={{ textAlign: "center" }}>

                            <RiMenuFill
                                onClick={showsleider}
                                style={{ cursor: "pointer" }}
                                size={25}
                            />
                            <div>قائمة</div>
                        </div>

                    </div>



                </div>

                <div className="header2" style={{ justifyContent: "center" }}>كشف حساب المستخدم : {username}</div>

                <div className="kshfdate">
                    <div className="kshfdate2">
                        <div className="p-2 d-flex">من الفترة 
                        :
                     
                        
                        
                        <input type="date" style={{ color: "#000", marginRight: 2 }} value={datefrom}  onChange={e => setdatefrom(e.target.value)} />
                         
                        </div>
                        <div className="p-2 d-flex">الى الفترة:
                            
                         <input type="date" style={{color:"#000",marginRight:2}} value={dateto} onChange={e => setdateto(e.target.value)}/>
                        </div>
                    </div>

                    <div className="btnkshf" onClick={serchkshfuser}>
                        بحث
                </div>
                </div>

            </div>
            <div className="husabmustakem">

                <Table
                    
                   
                    
                    scroll={{ x: 'calc(600px + 50%)'}}
                  
                    className="ant-table-thead2"
                    columns={columns}
                    dataSource={dt}
                    pagination={false}
                    bordered
                    summary={pageData => {
                        let totalmden = 0;
                        let totaldaen = 0;
                        let totalmdendol = 0;
                        let totaldaendol = 0;

                        pageData.forEach(({ mden, daen, amla }) => {
                            totalmden += amla === "دينار عراقي" ? parseFloat(mden)  : 0;
                            totaldaen += amla === "دينار عراقي" ? parseFloat(daen) : 0;
                            {/*totalmdendol += amla === "دولار" ? parseFloat(mden) : 0;*/}
                       {/*totaldaendol += amla === "دولار" ? parseFloat(daen) : 0;*/}
                        });

                        return (
                            <>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell className="text-center mblgiq">المبلغ الكلي</Table.Summary.Cell>
                                    <Table.Summary.Cell className="text-center mblgiq">
                                       
                                       
      
       
         <InputNumber
          style={{width: "100%",border:"none"}}
                            value={totalmden} 
                          
            bordered={false}
            readOnly
      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
    parser={value => value.replace(/\D/g, "")}
    />
     
  
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell className="text-center mblgiq">
                                      
                                        
       
         <InputNumber
          style={{width: "100%",border:"none"}}
                            value={totaldaen} 
                          
            bordered={false}
            readOnly
      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
    parser={value => value.replace(/\D/g, "")}
    />
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell className="mblgiq">
                                        <Text >دينار عراقي</Text>
                                    </Table.Summary.Cell>


                                </Table.Summary.Row>
                                
                                <Table.Summary.Row>
                                    <Table.Summary.Cell className="mblgiq">الرصيد</Table.Summary.Cell>
                                    <Table.Summary.Cell colSpan={2} className="mblgiq">
                                       
                                     <Text className="d-flex">
                                        <InputNumber
                                            style={{ width: "100%", border: "none" }}
                                            value={totalmden - totaldaen}
                          
                                            bordered={false}
                                            readOnly
                                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')  }
    parser={value => value.replace(/\D/g, "")}
                                        /> {totaldaen  > totalmden ? "دائن" : "مدين"}
                                        </Text>
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>
                               
                            </>
                        );
                    }}
                />

            </div>
            
            {managerusers ?
            <div className="printkshfuser" onClick={gotoprintkshf} >
                طباعة كشف الحساب
            </div>
            :""}

            {clickiconmenufolder_REDUCER ? (
                <div className="overfloeiconfolder" onClick={setoverflowmenu}></div>
            ) : (
                ""
            )}
            {showslideuser ? <SlideUsers clas="SlideUsers" /> : ""}


        </div>
    )
}

export default MaliaUser


