import React, { useEffect, useState } from 'react';
import { RiMenuFill } from 'react-icons/ri';
import './style.css';
import { actiontype } from "../../reducer/Reducer";
import { useStateValue } from "../../reducer/StateProvider";
import SlideUsers from '../NewUserForm/SlideUsers';
import axios from "../../utils/API";
import {   InputNumber, message, Progress} from 'antd';

import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';



const MaliaUpdate = () => {

     
    const idwsel = localStorage.getItem("idwsel");
    const datews = localStorage.getItem("date");
   
    const mblgswel = localStorage.getItem("mblgswel");
    const amlaws = localStorage.getItem("amla");
    const byean = localStorage.getItem("byean");
    
    const [key, setkey] = useState(idwsel);
    const [datewsel, setdatewsel] = useState(datews);
    const [mblg, setmblg] = useState(mblgswel);
    const [amla] = useState(amlaws);
    const [beyan, setbeyan] = useState(byean);
    

    const history = useHistory();
    const [progess, setprogess] = useState(0);
    const iduser = localStorage.getItem("idusee");
    const idmk = localStorage.getItem("idmustakdem");

    const [username, setusername] = useState("");
    const [MSG, setMSG] = useState("");
    const [btnadd, setbtnadd] = useState(false);
    
    
    
    
  const puser = async () => {
    const userone = await axios.post(`users/${iduser}`);
    if (userone.data.role === "مستخدم") {
      history.push("/HomeUserManage", { from: "ProtectedRoute" });
    } else {
    }
  };
  puser();
    ;
    //const [addmblg, setaddmblg] = useState(localStorage.getItem("addmblg"));
    const addmblg = localStorage.getItem("addmblg");
   
    
     const [detailsopen] = useState(false);
    const [showslideuser, setshowslideuser] = useState(false);

   

    const [
        {
            clickiconmenufolder_REDUCER

        },
        dispatch,
    ] = useStateValue();

    const setoverflowmenu = () => {
        dispatch({
            type: actiontype.SET_OVERMENUE,
            items: false,

        });

        dispatch({
            type: actiontype.SET_SHOWREdate,
            items: false,
        });

        dispatch({
            type: actiontype.SET_SHOWADDFOLDERFORM,
            items: false,
        });
        setshowslideuser(false);
    };
    const showsleider = async () => {

        setshowslideuser(true);
        dispatch({
            type: actiontype.SET_OVERMENUE,
            items: true,
        });

    };


    useEffect(() => {
        //setkey(idwsel);
        const usermustakem = async () => {
            const data = await axios.post(`users/${idmk}`);
            setusername(data.data.username);

        }
        usermustakem(idmk);

    }, [idmk]);


  





   
/*
    const AddMblagTouser = () => {
        setaddmblg(true);
        settsdedmbla(false);
        setdetailsopen(true);
        setgotomal(true);
       // NewAdd();
    }

    const TsdedMblagTouser = () => {
        setaddmblg(false);
        settsdedmbla(true);
        setdetailsopen(true);
        setgotomal(true);
       // NewAdd();
    }
*/
    useEffect(() => {
    if (progess === 100) {
      setTimeout(() => {
         message.success(MSG);
        setprogess(0);
      }, 1000);
    }
    }, [progess,MSG]);
    
    const NewAdd = () => {
         setkey(uuidv4());
        setbtnadd(false);
        setmblg("");
        setbeyan("");
        setdatewsel("");

    }

    const calncel = async() => {
        NewAdd();
          const userone = await axios.post(`users/${iduser}`);
          if (userone.data.role === "مدير نظام") {
        history.push("/MaliaUser", { from: "MaliaUpdate" });
    } else {
       history.push("/Mustakdem", { from: "MaliaUpdate" });
    }
        
    }

    
    const UpdateWasel = () => {
    
        
        if (datewsel==="") {
            message.error("يرجى تحديد التاريخ")
        }
        else if (mblg==="") {
            message.error("يرجى تحديد المبلغ")
        }
        else
        {
              console.log(addmblg)
           if (addmblg==="add")
                {
                    axios.put(
                        "Malia/",
                        {
                            iduser: idmk,
                            key: key,
                            date: datewsel,
                            mden: mblg,
                            daen: 0,
                            amla: amla,
                            byean: beyan,
                            useradd: iduser
         
                        },
                        {
                            onUploadProgress: (progressEvent) =>
                                //console.log(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                                setprogess(
                                    Math.round((progressEvent.loaded * 100) / progressEvent.total)
                                ),
                        }
                    );
                     setMSG("تم تعديل الوصل");
                    //setbtnadd(true);
            }
            else{
               axios.put(
                        "Malia",
                        {
                            iduser: idmk,
                            key: key,
                            date: datewsel,
                            mden: 0,
                            daen: mblg,
                            amla: amla,
                            byean: beyan,
                            useradd: iduser
         
                        },
                        {
                            onUploadProgress: (progressEvent) =>
                                //console.log(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                                setprogess(
                                    Math.round((progressEvent.loaded * 100) / progressEvent.total)
                                ),
                        }
               );
               
                     setMSG("تم تعديل الوصل");
                     //setbtnadd(true);
            }
           
        }
      
    }

    const deletewsel =  () => {
        try {
               axios.delete(`Malia/${idmk}&${key}`, {
      onUploadProgress: (progressEvent) =>
        //console.log(Math.round((progressEvent.loaded * 100) / progressEvent.total));
        setprogess(
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        ),
        });
         setMSG("تم حذف الوصل");
            NewAdd();
             history.push("/MaliaUser", { from: "Malia" });
        } catch (err) {
            
        }
      
       
        
       
           
 
    }

    const gotokshfhusab = () => {
        history.push("/MaliaUser", { from: "Malia" });
    }

   // console.log(idwsel[1])


    
    return (
        <div>

            <div className="fixed">


                <div className="navbar">

                    <div className="logo">
                        <img
                            src={window.location.origin + "/img/logo.png"}
                            style={{ height: "2em" }}
                            className="logoinuserform"
                            alt="logo"
                        />
                    </div>
                    <h3 style={{ color: "#FFF" }}>قسم المالية</h3>
                    <div className="iconright_malia">

                        <div style={{ textAlign: "center" }}>

                            <RiMenuFill
                                onClick={showsleider}
                                style={{ cursor: "pointer" }}
                                size={25}
                            />
                            <div>قائمة</div>
                        </div>

                    </div>



                </div>

                <div className="header2" style={{ justifyContent: "center" }}>كشف حساب المستخدم : {username}
                </div>

            

             

                <div className={detailsopen ? "detailmblg" : "detailmblg"}>
                    <div style={{width:"100%",textAlign:"center"}}>
                       
                    <div>  <span>التاريخ :</span> </div>
                        <input value={datewsel} onChange={(e)=>setdatewsel(e.target.value)} className="inputmblg" type="date"  required pattern="\d{1,2}/\d{1,2}/\d{4}"/>
                     

                    </div>

                    <div style={{width:"100%",textAlign:"center"}}>
                       
                    <div>  <span>المبلغ :</span> </div>
                        
                        <InputNumber
                            value={mblg} onChange={(value)=>setmblg(value)} 
                           style={{width: "80%",
    padding: "5px",
    textalign: "center",
    outline: "none",
    border: "1px solid #2f2f2f",
    background: "#fff",
    borderradius:" 2px",
    fontweight: "800"}}
    
      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
    parser={value => value.replace(/\D/g, "")}
    />
                    </div>

                  

                      <div style={{width:"100%",textAlign:"center"}}>
                       <div> <span >البيان :</span> </div>
                        <textarea value={beyan} onChange={(e)=>setbeyan(e.target.value)} className="inputmblg"></textarea>
                      
                   <Progress percent={progess} />
                    </div>

                   <div className={progess>0?"btnmblgzero":"btnmblg"}>
                     
                    <div className={!btnadd ? "btnmb btnupdate" :" btnmb btnupdate opt"}  onClick={UpdateWasel}>تعديل</div>
                    <div className={!btnadd ? "btnmb btndelete" :"btnmb btndelete opt"} onClick={deletewsel}>حذف</div>
                        <div className="btnmb btnprint" onClick={gotokshfhusab}>كشف حساب</div>
                       
                </div>
                      <div className="btnmbcancel" onClick={calncel}>الغاء</div>
                </div>

               


             </div>
{clickiconmenufolder_REDUCER ? (
                <div className="overfloeiconfoldermal" onClick={setoverflowmenu}></div>
            ) : (
                ""
            )}
            {showslideuser ? <SlideUsers clas="SlideUsers" /> : ""}
        </div>
    )
}

export default MaliaUpdate


