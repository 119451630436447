import React, { useEffect, useState } from "react";
import { BiRename } from "react-icons/bi";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { MdDeleteForever } from "react-icons/md";
import { actiontype } from "../../reducer/Reducer";
import { useStateValue } from "../../reducer/StateProvider";
import axios from "../../utils/API";

import openMessage from "./openMessage";
import {   useHistory } from "react-router-dom";

import { Popconfirm ,message} from 'antd';

import "./style.css";

const IconFolder = ({ namefolder, folderid ,folder,typefile,filname}) => {
  //const [ShowRenamwForm, setShowRenamwForm] = useState(false);
 const baseURLImg =
 "https://seadimahbobaapi.xyz/ftp/uploads/";

  //console.log(typefile)
  const history = useHistory();
  const userid = localStorage.getItem("idmustakdem");

  const useridAuth = localStorage.getItem("idusee");

  const [clickiconmenufolder, setclickiconmenufolder] = useState(false);

  const [{ clickiconmenufolder_REDUCER }, dispatch] = useStateValue();
  const setoverflowmenu = () => {
    dispatch({
      type: actiontype.SET_OVERMENUE,
      items: true,
    });

    setclickiconmenufolder(!clickiconmenufolder);
  };

  useEffect(() => {
    if (clickiconmenufolder_REDUCER === false) {
      setclickiconmenufolder(false);
    }
  }, [clickiconmenufolder_REDUCER]);

  const ShowRename = () => {
    dispatch({
      type: actiontype.SET_SHOWRENAME,
      items: true,
    });
    setclickiconmenufolder(!clickiconmenufolder);
  };

  const confirm = async (e)=> {
    try {
      
      
        if (folder===true) {
         
         const userone = await axios.post(`users/${useridAuth}`);
          if (userone.data.permistoin_users[0].delete==="لا") {
      message.error("لا تمتلك صلاحية حذف الملف")
    }
    
    else {

          axios.delete(`files/${filname}`, {
            headers: {
              "Content-Type": "application/json",
            },
            data: { iduser: userid, idfolder: folderid },
          });

          openMessage();
          

          dispatch({
        type: actiontype.SET_UPDATEDATAAFTERDELETEFILE,
        items:true,
          })

          dispatch({
          type: actiontype.SET_OVERMENUE,
          items: false,
          });
          
         
    }
        }
        else {
          const userone = await axios.post(`users/${useridAuth}`);
    
    if (userone.data.permistoin_users[0].deletfolder==="لا") {
      message.error("لا تمتلك صلاحية حذف المجلد")
    }
    else {
           axios.delete(`folder`, {
            data: { iduser: userid, idfolder: folderid },
            onUploadProgress: (progressEvent) =>
              console.log(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              ),
          });

        openMessage();
      
        dispatch({
          type: actiontype.SET_OVERMENUE,
          items: false,
        });
        }
        }
    
 

    } catch (error) {
      console.log("error");
    }
  
    
}

function cancel(e) {
 
  }




  const showfile = () => {
   // window.location.href = `${baseURLImg}/${filname}`;
     window.location.href =  `${baseURLImg}/${filname}`;
    

      
    /*
  
    if (typefile === "application/pdf")
    {
  
      document.write('<a href="'+ document.referrer +'"></a>');
 
      

      
    }
    */

  }

  return (
    <div className="iconfolder">
      <HiOutlineDotsVertical
        style={{ cursor: "pointer" }}
        size={25}
        onClick={setoverflowmenu}
      />

      <div
        className={
          !clickiconmenufolder ? "menuiconfolder" : "activmen menuiconfolder"
        }
      >
        <div className="headermenufolder h4wrap">{namefolder}</div>
        {!folder ?
          <a onClick={ShowRename} >
            اعادة تسمية <BiRename />
          </a>
          : ""}

        <>
          
          <Popconfirm
            title={folder ? "هل انت متأكد من حذف هذا الملف ؟ " : "هل انت متأكد من حذف هذا المجلد ؟ "}
   
    onConfirm={confirm}
    onCancel={cancel}
    okText="نعم"
     cancelText="لا"
     style={{ zIndex: 9898798959,left:10 }}
            
            
  >
    <a className="adelete">حذف <MdDeleteForever /></a>
          </Popconfirm>
          
        </>
        {folder ? 
          <>

          
            <a href={`${baseURLImg}/${filname}`} target="_blank" rel="noreferrer" >
              
                عرض الملف 
          
              <MdDeleteForever />
            </a>
              
       
          </>
        :""}
        {/*<p>نقل الى <BiTransfer/></p>*/}
      </div>
    </div>
  );
};

export default IconFolder;
