import React from 'react'

const Privice = () => {
    return (
        <div style={{display:"flex",flexDirection:"column",}}>
            <div  className="privecemain">
               شروط الاستخدام وسياسة الخصوصية

            </div>

            <div style={{padding:10}}>
                <h3 style={{textAlign:"right",padding:30,lineHeight:2}}>
                    بتصفح هذا الموقع، أنت تقر بأنك قد قرأت وفهمت ما يلي من شروط الاستخدام وسياسة الخصوصية في مجملها، بما في ذلك أي تحديثات قد تُنشر على الموقع من وقت لآخر. تحتفظ شركة saedimhboba بحقها في مراجعة شروط الاستخدام وسياسة الخصوصية بتحديث هذا المنشورة
                </h3>
            </div>

            <div style={{ textAlign: "right", padding: 30, lineHeight: 2,flexDirection:"column",display:"flex",justifyContent:"space-between",height:"100vh" }}>
                <div>
    1.    ما هي المعلومات التي تجمعوها عني؟
قد يتم جمع معلومات شخصية وغير شخصية عن كل زائر من هذا الموقع. تعتمد نوعية المعلومات على الإجراءات والتفاعلات التي تقوم بها مع الموقع.
                </div>
            
                <div>
                المعلومات الشخصية
لا يتم تجميع المعلومات الشخصية إلا إذا قدمت طواعية هذه المعلومات حسب الحاجة إليها، على سبيل المثال، أثناء عمليات التسجيل وتقديم الطلبات. تبقى أي معلومات شخصية قد تشاركها معنا سرية. لا يتم بيع اسمك أو أي بيانات تعريف تخصك أو مشاركتها مع أي طرف خارجي ما لم تكن مطلوبة لإنجاز الطلبات أو الخدمات، أو استرداد المبالغ المدفوعة فيها. المزيد من المعلومات حول استخدام هذه المعلومات سيتم ذكره لاحقًا في نص سياسة الاستخدام هذه.
    
</div>

                <div>
المعلومات غير الشخصية
يتم جمع المعلومات غير الشخصية من خلال تقنية موقعنا لخلق تجربة أكثر شخصية وذات صلة أثناء زيارتك. تمكننا هذه المعلومات أيضا من تحسين أداء الموقع لخدمتك بشكل أفضل.

                </div>

                <div>
2.    كيف تجمعون وتستخدمون المعلومات الشخصية؟
جمع المعلومات الشخصية
قد يتم تجميع معلومات التعريف الشخصية أثناء عمليات التسجيل، أو تقديم الطلب وإعداد الفواتير، أو أثناء التعامل مع خدمة العملاء أو عند اشتراكك في مسابقة. لا يتم أبدا مشاركة المعلومات الشخصية بطرق ليست ذات علاقة بالطرق الموضحة بالأسفل بدون إخطارك بالنية مسبقا، وأيضا إعطائك الفرصة لاستبعاد أو حظر هذه الاستخدامات غير ذات الصلة.

                </div>
                <div>
التسجيل
أثناء عملية التسجيل، قد تتم مطالبتك بإعطاء اسمك وعنوان بريدك الإلكتروني لأغراض التثبت والتحقق. قد تكون هذه الخطوات ضرورية لضمان أن المستخدمين يتم التعرف عليهم بشكل صحيح وكذلك التصديق عليهم كمستخدمين مؤهلين. قد تكون بعض العروض والعروض الترويجية على الموقع مقتصرة على أنواع معينة من المستخدمين (مثل الطلاب أو أعضاء هيئة التدريس أو العاملين) أو على أعضاء مؤسسات معينة. من خلال تقديم عنوان البريد الإلكتروني، وبالتالي التحقق من الحساب، يمكن الاستفادة من هذه العروض والعروض الترويجية.

                </div>
                <div>
الطلب والفوترة
عند تقديم طلب عبر أحد مواقع  بشأن المنتجات غير المجانية، قد يحتاج المستخدم إلى تقديم معلومات بطاقة الائتمان. عند طلب منتج يخضع للتسليم المادي، قد يحتاج المستخدم إلى تقديم معلومات الشحن اللازمة (مثل العنوان البريدي). يمكن طلب هذه المعلومات لتلبية الطلب و/أو أغراض الامتثال لمتطلبات عملية التصدير. لا يتم تخزين معلومات بطاقتك الائتمانية على الخادم الخاص بنا؛ بل يتم نقلها إلى نظام معالجة البطاقات الائتمانية للمصادقة عليها. كما قد يُطلب اسمك وبريدك الإلكتروني لإعداد الإيصال الخاص بك على الإنترنت، وكذلك لكي نتمكن من إرسال رسائل البريد الإلكتروني ذات الصلة بالطلب.

                </div>

                <div>
            خدمة العملاء
قد يتم تجميع معلومات التعريف الشخصية في حال أبلغت عن مشكلة أو قدمت استفسارًا إلى فريق (فرق) خدمة العملاء. قد يتم الاحتفاظ بسجل لتلك المراسلة، وذلك لتقديم خدمة عملاء مثالية لك.

الاشتراك في مسابقة أو عرض ترويجي
علاوة على ذلك، قد يتم جمع معلومات منك للدخول إلى مسابقات وعروض ترويجية مشابهة عندما تختار المشاركة طوعًا. جميع المسابقات لها شروطها وأحكامها الخاصة التي يمكن الاطلاع عليها على صفحة المسابقة.

استخدام المعلومات الشخصية
لا يتم بيع أو استبدال أو إيجار معلوماتك الشخصية للآخرين.        
</div>

            </div>

               <div  className="privecemain">
                <h3>للتواصل معنا</h3>
                
                <h5 >البريد الالكتروني</h5>
                <h5>osamaalrsam43@gmail.com</h5>
                
                  <h5>رقم الهاتف</h5>
                 <h5>009647810094624</h5>

            </div>
        </div>
    )
}

export default Privice
