import React, { useEffect, useState } from "react";
import { RiMenuFill } from "react-icons/ri";
import axios, {ex} from "../../utils/API";
import SlideUsers from "../NewUserForm/SlideUsers";
import { actiontype } from "../../reducer/Reducer";
import { useStateValue } from "../../reducer/StateProvider";
import { message, Progress } from "antd";
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from "react-router";

const NotiFyAllUser = () => {

  
       const [
        {
            clickiconmenufolder_REDUCER

        },
        dispatch,
    ] = useStateValue();

    const iduser = localStorage.getItem("idusee");
    const idmk = localStorage.getItem("idmustakdem");

   const history = useHistory();
  const puser = async () => {
    const userone = await axios.post(`users/${iduser}`);
    if (userone.data.role === "مستخدم") {
      history.push("/HomeUserManage", { from: "ProtectedRoute" });
    } else {
    }
  };
  puser();

    const [btnadd, setbtnadd] = useState(false);
    const [progess, setprogess] = useState(0);
    const [MSG, setMSG] = useState("");
    const [showslideuser, setshowslideuser] = useState(false);
  const [username, setusername] = useState("");
      const [tokennotiuser, settokennotiuser] = useState("");

    const [titalnoty, settitalnoty] = useState("");
    const [opjnoty, setopjnoty] = useState("");
    const [key, setkey] = useState(uuidv4());
    const [datauser, setdatauser] = useState([{}]);

   

    useEffect(() => {
        const getusers = async () => {
    const user = await axios.get("users");
    setdatauser(user.data);
        };
        getusers();
    }, [])

    

    const showsleider = async () => {

        setshowslideuser(true);
        dispatch({
            type: actiontype.SET_OVERMENUE,
            items: true,
        });

    };

  

      const setoverflowmenu = () => {
        dispatch({
            type: actiontype.SET_OVERMENUE,
            items: false,

        });

        dispatch({
            type: actiontype.SET_SHOWREdate,
            items: false,
        });

        dispatch({
            type: actiontype.SET_SHOWADDFOLDERFORM,
            items: false,
        });
        setshowslideuser(false);
    }

    /*
    useEffect(() => {
        //setkey(idwsel);
        const usermustakem = async () => {
            const data = await axios.post(`users/${idmk}`);
          setusername(data.data.username);
          settokennotiuser(data.data.tkn)

        }
        usermustakem(idmk);

    }, [idmk]);
  */


      useEffect(() => {
    if (progess === 100) {
      setTimeout(() => {
         message.success(MSG);
        setprogess(0);
      }, 1000);
    }
      }, [progess]);
    
    const NewAdd = () => {
        setkey(uuidv4());
      setbtnadd(false)
        settitalnoty("");
        setopjnoty("");
 
  }
  
    const AddNoty =async () => {
        if (titalnoty === "") {
            message.error("يرجى تحديد العنوان")
        }
        else if (opjnoty === "") {
            message.error("يرجى تحديد الموضوع")
        }
        else
        {

       
                
            {
                datauser.map((res, i) => {
                
                
        var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");

var raw = JSON.stringify({
  "totoken": res.tkn,
  "titlenot": titalnoty,
  "bodynot": opjnoty
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch("https://seadimahbobaapi.xyz/api/v1/users/sendnotitouser/noty", requestOptions)
  .then(response => response.text())
  .catch(error => console.log('error', error));


     
          
          
          axios.post(
                        "NotyUser/",
              {
                            
                            iduser:  res.iduser,
                            id: key,
                            date: new Date(),
                            tital: titalnoty,
                            ojb: opjnoty,
                            red: false,
                            useradd: iduser
         
                        },
                        {
                            onUploadProgress: (progressEvent) =>
                                //console.log(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                                setprogess(
                                    Math.round((progressEvent.loaded * 100) / progressEvent.total)
                                ),
                        }
                    );

                    
                    setMSG("تم اضافة الاشعار");
          setbtnadd(true);

          
          setTimeout(() => {
           window.location.reload();
          }, 6000);
          
             
                                   

             

          })
            }

        }
 
    }
    
    

  return (
    <div>
      <div className="fixed">
        <div className="navbar">
          <div className="logo">
            <img
              src={window.location.origin + "/img/logo.png"}
              style={{ height: "2em" }}
              className="logoinuserform"
              alt="logo"
            />
          </div>
          <h3 style={{ color: "#FFF" }}>قسم الأشعارات</h3>
          <div className="iconright_malia">
            <div style={{ textAlign: "center" }}>
              <RiMenuFill
                onClick={showsleider}
                style={{ cursor: "pointer" }}
                size={25}
              />
              <div>قائمة</div>
            </div>
          </div>
        </div>

        <div className="header2" style={{ justifyContent: "center" }}>
         اشعار كل المستخدمين
              </div>
              
          </div>

          <div className="notify_main">

              <div style={{width:"100%",textAlign:"center"}}>
                       
                    <div>  <span>العنوان :</span> </div>
                        <input className="inputmblg"  type="text" value={titalnoty} onChange={(e)=>settitalnoty(e.target.value)} />
                     

                    </div>
              <div style={{width:"100%",textAlign:"center"}}>
                       <div> <span >الموضوع :</span> </div>
                        <textarea className="inputmblg"  style={{height:200}} value={opjnoty} onChange={(e)=>setopjnoty(e.target.value)} ></textarea>
                      
                   <Progress percent={progess} />

                  <div className={progess > 0 ? "btnmblgzero" : "btnmblg"}>
                      <div className="btnmb btnupdate" onClick={NewAdd}>جديد</div>
                        <div className={btnadd ? "btnmbzro" :"btnmb "}onClick={AddNoty}>اضافة</div>
                         
                       
                  </div>
                  
                    </div>

          </div>

            
          
          {clickiconmenufolder_REDUCER ? (
                <div className="overfloeiconfoldermal" onClick={setoverflowmenu}></div>
            ) : (
                ""
            )}
          {showslideuser ? <SlideUsers clas="SlideUsers" /> : ""}
          
    </div>
  );
};

export default NotiFyAllUser;
