import React, { useEffect, useState } from "react";
import { RiMenuFill } from "react-icons/ri";
import axios from "../../utils/API";
import SlideUsers from "../NewUserForm/SlideUsers";
import { actiontype } from "../../reducer/Reducer";
import { useStateValue } from "../../reducer/StateProvider";
import { message, Progress } from "antd";
import { v4 as uuidv4 } from 'uuid';

const Amaluser = () => {

       const [
        {
            clickiconmenufolder_REDUCER

        },
        dispatch,
    ] = useStateValue();

    const iduser = localStorage.getItem("idusee");
    const idmk = localStorage.getItem("idmustakdem");

    const [btnadd, setbtnadd] = useState(false);
    const [progess, setprogess] = useState(0);
    const [MSG, setMSG] = useState("");
    const [showslideuser, setshowslideuser] = useState(false);
    const [username, setusername] = useState("");

    const [titalnoty, settitalnoty] = useState("");
    const [opjnoty, setopjnoty] = useState("");
    const [key, setkey] = useState(uuidv4());
    
    const showsleider = async () => {

        setshowslideuser(true);
        dispatch({
            type: actiontype.SET_OVERMENUE,
            items: true,
        });

    };

  

      const setoverflowmenu = () => {
        dispatch({
            type: actiontype.SET_OVERMENUE,
            items: false,

        });

        dispatch({
            type: actiontype.SET_SHOWREdate,
            items: false,
        });

        dispatch({
            type: actiontype.SET_SHOWADDFOLDERFORM,
            items: false,
        });
        setshowslideuser(false);
    }

    
    useEffect(() => {
        //setkey(idwsel);
        const usermustakem = async () => {
            const data = await axios.post(`users/${idmk}`);
            setusername(data.data.username);

        }
        usermustakem(idmk);

    }, [idmk]);


      useEffect(() => {
    if (progess === 100) {
      setTimeout(() => {
         message.success(MSG);
        setprogess(0);
      }, 1000);
    }
      }, [progess]);
    
    const NewAdd = () => {
        setkey(uuidv4());
      setbtnadd(false)
        settitalnoty("");
        setopjnoty("");
 
    }

    const AddNoty = async () => {
        if (opjnoty === "") {
          message.error("يرجى تحديد الموضوع");
        } else {
          axios.post(
            "Amal/",
            {
              nameuser: username,
              iduser: idmk,
              note: opjnoty,
              date: new Date(),
              red: false,
              key: key,
            },
            {
              onUploadProgress: (progressEvent) =>
                //console.log(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                setprogess(
                  Math.round((progressEvent.loaded * 100) / progressEvent.total)
                ),
            }
          );
          setMSG("تم اضافة اعمال جديدة");
          setbtnadd(true);
        }
      };

      
    

  return (
    <div>
      <div className="fixed">
        <div className="navbar">
          <div className="logo">
            <img
              src={window.location.origin + "/img/logo.png"}
              style={{ height: "2em" }}
              className="logoinuserform"
              alt="logo"
            />
          </div>
          <h3 style={{ color: "#FFF" }}>قسم الاعمال</h3>
          <div className="iconright_malia">
            <div style={{ textAlign: "center" }}>
              <RiMenuFill
                onClick={showsleider}
                style={{ cursor: "pointer" }}
                size={25}
              />
              <div>قائمة</div>
            </div>
          </div>
        </div>

        <div className="header2" style={{ justifyContent: "center" }}>
          اضافة عمل من قبل : {username}
              </div>
              
          </div>

          <div className="notify_main">

              
              <div style={{width:"100%",textAlign:"center"}}>
                       <div> <span >الموضوع :</span> </div>
                        <textarea className="inputmblg"  style={{height:200}} value={opjnoty} onChange={(e)=>setopjnoty(e.target.value)} ></textarea>
                      
                   <Progress percent={progess} />

                  <div className={progess > 0 ? "btnmblgzero" : "btnmblg"}>
                      <div className="btnmb btnupdate" onClick={NewAdd}>جديد</div>
                        <div className={btnadd ? "btnmbzro" :"btnmb "}onClick={AddNoty}>اضافة</div>
                         
                       
                  </div>
                  
                    </div>

          </div>

            
          
          {clickiconmenufolder_REDUCER ? (
                <div className="overfloeiconfoldermal" onClick={setoverflowmenu}></div>
            ) : (
                ""
            )}
          {showslideuser ? <SlideUsers clas="SlideUsers" /> : ""}
          
    </div>
  );
};

export default Amaluser;
