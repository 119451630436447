import React, { useEffect, useState } from "react";
import "./style.css";
import axios from "../../utils/API";
import IconFolder from "./IconFolder";
import firebase from '../../firebase';

//icon
import {
  RiArrowLeftSLine,

  RiFileUploadLine,
  RiFolderAddLine,
  RiMenuFill,
  RiSearchLine,
} from "react-icons/ri";

import { TiHome } from "react-icons/ti";
import { actiontype } from "../../reducer/Reducer";
import { useStateValue } from "../../reducer/StateProvider";
import Rename from "./Rename";
import AddNewFolder from "./AddNewFolder";

import {
  AiFillFolder,

} from "react-icons/ai";
import { useHistory, useLocation } from "react-router-dom";

import SlideUsers from "./SlideUsers";
import { message } from "antd";

import { Input } from 'antd';

//end icon

const NewUserForm = () => {
  const userid = localStorage.getItem("idmustakdem");
  const useridAuth = localStorage.getItem("idusee");

  const history = useHistory();
  const location = useLocation();

  // const myparam = location.state.params;
  const [serchin, setserchin] = useState(true);
  const [datauser, setdatauser] = useState([]);
  const [namefolder, setnamefolder] = useState("");
  const [showslideuser, setshowslideuser] = useState(false);

  const [idfolder, setidfolder] = useState("");
  const [classactive, setclassactive] = useState(false);
  //const [showaddfolderform,setshowaddfolderform] = useState(false);
/*
   window.OneSignal = window.OneSignal || [];
  const OneSignal = window.OneSignal;
*/
  const [
    {
      clickiconmenufolder_REDUCER,
      showrenameform,
      showfolderform,
      afterdeletfile,
    },
    dispatch,
  ] = useStateValue();
  const setoverflowmenu = () => {
    dispatch({
      type: actiontype.SET_OVERMENUE,
      items: false,
    });

    dispatch({
      type: actiontype.SET_SHOWRENAME,
      items: false,
    });

    dispatch({
      type: actiontype.SET_SHOWADDFOLDERFORM,
      items: false,
    });
    setshowslideuser(false);
  };

  const [filefolder, setfilefolder] = useState([]);
  //  const [DF, seDF] = useState(false);

  
  /*
useEffect( ()=>{
     
  const Fdata = async () => {
    const UserIDFromOneSignal = await OneSignal.getUserId();
      axios.post("users/savetoken/token",{"iduser":userid,"tkn":UserIDFromOneSignal})
  }

  Fdata();
},[userid])
  */
  
  

  useEffect(() => {

    const abotrcoat = new AbortController();
    try {
      if (serchin === true) {
        const fetchdata = async (userid, idfolder) => {
          const userr = await axios.post(`folder/${userid}`, { signal: abotrcoat.signal });
          setdatauser(userr.data.folder);
          if (afterdeletfile === true) {
            datauser
              .filter((res) => res.id === idfolder)
              .map((rs) => setfilefolder(rs.files));
          }

          if (filefolder.length === 0) {
            dispatch({
              type: actiontype.SET_UPDATEDATAAFTERDELETEFILE,
              items: false,
            });
          }

          // return ()=>abotrcoat.abort(); 
        };

        fetchdata(userid, idfolder)


      }
    } catch (error) {
      console.log("error");
    }
  }, [userid, idfolder, afterdeletfile, datauser, dispatch, filefolder.length, serchin]);


  const ShowِAddNewFolder = async () => {
    const userone = await axios.post(`users/${useridAuth}`);
    if (userone.data.permistoin_users[0].addfolder === "لا") {
      message.error("لا تمتلك صلاحية اضافة مجلد")
    }
    else {
      dispatch({
        type: actiontype.SET_OVERMENUE,
        items: true,
      });

      dispatch({
        type: actiontype.SET_SHOWADDFOLDERFORM,
        items: true,
      });
    }
  };
  //const [filefolderM, setfilefolderM] = useState([]);

  const EnterToFolder = (e, contfolder, contfile, folderidfromdata) => {
    /*datauser
      .filter((res) => res.id === folderidfromdata)
      .map((rs) =>
        rs.files.length === 0
          ? setfilefolder([{ originalname: "" }])
          : setfilefolder(rs.files)
      );
*/

    // datauser.filter((res) => res.id === folderidfromdata).map((rs) =>console.log(rs.files) );
    setnamefolder(e);
    setidfolder(folderidfromdata);
    setclassactive(true);
    history.push('/Files', { params: folderidfromdata, parmnamefolder: e, parmpath: location.pathname })

    // <Files folderid={folderidfromdata} foldername={e} />
    //getnamefolder();
  };

  //console.log(filefolder);

  const EnterToFolderM = (e, contfolder, contfile, folderidfromdata) => {
    setnamefolder(e);
    setidfolder(folderidfromdata);
    setclassactive(true);
  };

  //console.log(filefolder);

  const gotohome = () => {
    setnamefolder("");
    setfilefolder([]);
    dispatch({
      type: actiontype.SET_UPDATEDATAAFTERDELETEFILE,
      items: false,
    });
  };

  const GoToUplodeFile = async () => {
    const userone = await axios.post(`users/${useridAuth}`);
    if (userone.data.permistoin_users[0].add === "لا") {
      message.error("لا تمتلك صلاحية اضافة الملف")
    }

    else {
      history.push("/UplodeFile", { from: "index" });
      localStorage.setItem("idfolder", idfolder);
      localStorage.setItem("namefolder", namefolder);
    }
  };

  const showsleider = async () => {

    setshowslideuser(true);
    dispatch({
      type: actiontype.SET_OVERMENUE,
      items: true,
    });

  };


  /*Search input */



  const { Search } = Input;

  const [serch, setserch] = useState(false);
  const onSearch = value => {
    setserchin(false);
    if (value === "") {
      setserchin(true);
    }
    else {

      setserchin(false);
      const getserch = datauser.filter(rs => rs.namefolder.includes(value)).map(res => res)
      setdatauser(getserch)
    }
  };


  const closeserch = () => {
    setserch(false);
    setserchin(true);
  }

  /*End Search input */

  return (
    <div>
      {/*Start navbar*/}
      <div className="navbar">
        {serch ?
          <div className="formserch">

            <div style={{ flexBasis: "85%", direction: "rtl" }}><Search allowClear placeholder="يرجى ادخال اسم الملف" onSearch={onSearch} enterButton /></div>
            <div className="closeserch" onClick={closeserch}>X</div>
          </div>
          :
          <>

            <div className="logo">
              <img
                src={window.location.origin + "/img/logo.png"}
                style={{ height: "2em" }}
                className="logoinuserform"
                alt="logo"
              />
            </div>

            <div className="iconright">
              {filefolder.length > 0 ? (

                <div style={{ textAlign: "center" }}>

                  <RiFileUploadLine
                    style={{ cursor: "pointer" }}
                    size={25}
                    onClick={GoToUplodeFile}
                  />

                  <div>ملف</div>
                </div>
              ) : (
                ""
              )}
              <div style={{ textAlign: "center" }}>
                <RiSearchLine style={{ cursor: "pointer" }} size={25} onClick={() => setserch(true)} />
                <div>بحث</div>
              </div>

              <div style={{ textAlign: "center" }}>
                <RiFolderAddLine
                  style={{ cursor: "pointer" }}
                  onClick={ShowِAddNewFolder}
                  size={25}
                />
                <div>مجلد</div>
              </div>

              <div style={{ textAlign: "center" }}>

                <RiMenuFill
                  onClick={showsleider}
                  style={{ cursor: "pointer" }}
                  size={25}
                />
                <div>قائمة</div>
              </div>


            </div>
          </>
        }
      </div>

      {/*End navbar*/}

      {/*Start Headr*/}
      <div className="header">
        <TiHome style={{ cursor: "pointer" }} size={25} onClick={gotohome} />
        {namefolder !== "" ? (
          <div>
            <RiArrowLeftSLine size={15} className="h4wrap" /> {namefolder}{" "}
          </div>
        ) : (
          <div>
            <RiArrowLeftSLine size={15} /> ملفاتي{" "}
          </div>
        )}

        {filefolder.length > 0 ? (
          <div
            onClick={gotohome}
            style={{
              cursor: "pointer",
              marginRight: "auto",
              marginLeft: 10,
              color: "#000",
            }}
          >
            رجوع
          </div>
        ) : (
          ""
        )}
      </div>

      {/*End Headr*/}

      {/*Start Content Folder And File*/}

      {clickiconmenufolder_REDUCER ? (
        <div className="overfloeiconfolder" onClick={setoverflowmenu}></div>
      ) : (
        ""
      )}

      {datauser.map((res, i) => (
        <div
          key={i}
          className={
            classactive ? "ContentFolderAndFile" : "ContentFolderAndFile"
          }
        >
          <AiFillFolder
            className="svgicon"
            size={25}
            fill=" #3857ff"
            onClick={(e) => EnterToFolder(res.namefolder, 0, 0, res.id)}
          />
          <div
            className="titleFolder"
            onClick={(e) => EnterToFolder(res.namefolder, 0, 1, res.id)}
          >
            <h4>{res.namefolder}</h4>
            <div className="folderdetails">
              <p>{res.files.length} ملف</p>
            </div>
          </div>

          <div
            onClick={(e) => EnterToFolderM(res.namefolder, 0, 0, res.id)}
          >
            {" "}
            <IconFolder
              namefolder={res.namefolder}
              folderid={idfolder}
            />{" "}
          </div>
        </div>
      ))}

      {showrenameform ? (
        <Rename namefolder={namefolder} idfolder={idfolder} />
      ) : (
        ""
      )}

      {showfolderform ? <AddNewFolder /> : ""}

      {showslideuser ? <SlideUsers clas="SlideUsers" /> : ""}



      {/*End Content Folder And File*/}
    </div>
  );
};

export default NewUserForm;
