import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import LogIn from "./components/LogIn";
import Users from "./components/Users";

import AddUsers from "./components/Users/AddUsers";
import UpdateUsers from "./components/Users/UpdateUsers";

import ProtectedRoute from "./components/ProtectedRoute";

import NewUserForm from "./components/NewUserForm/index";
import NewUploadeFile from "./components/NewUserForm/NewUploadeFile";
import NoInternet from "./components/NewUserForm/NoInternet";
import Files from "./components/NewUserForm/Files";
import MaliaUser from "./components/Malia/MaliaUser";
import Malia from "./components/Malia/Malia";
import MaliaUpdate from "./components/Malia/MaliaUpdate";
import PrintKshf from "./components/Malia/PrintKshf";
import NotiFy from "./components/NotiFy/NotiFy";
import NotyUser from "./components/NotiFy/NotyUser";
import Amal from "./components/Amal/Amal.jsx";
import NewAmal from "./components/Amal/NewAmal";
import Amaluser from "./components/Amal/Amaluser";
import NotiFyAllUser from "./components/NotiFy/NotiFyAllUser";
import HomeUserManage from "./components/NewUserForm/HomeUserManage";
import HomeAdminManage from "./components/NewUserForm/HomeAdminManage";
import LoginNews from "./components/LogIn/LoginNews";
import Privice from "./components/NewUserForm/Privice";

const App = () => {

    const LOGINUSER = localStorage.getItem("LOGINUSER");

  /*
  window.OneSignal = window.OneSignal || [];
  const OneSignal = window.OneSignal;

  useEffect(() => {
    OneSignal.push(() => {
      OneSignal.init({
        appId: "ef933bf4-5470-4979-b33b-33ff60bef9ac", //STEP 9
        promptOptions: {
          slidedown: {
            enabled: true,
            actionMessage:
              "نود أن نظهر لك إشعارات بآخر الأخبار والتحديثات حول الفئات التالية.",
            acceptButtonText: "نعم",
            cancelButtonText: "لا",
          },
        },
        welcomeNotification: {
          title: "Seadi Mahboba",
          message: "شكرا لك",
        },
      });
    });
  });
*/
  return (
    <div>
    

        <Router>
        <Switch>
           
        
       <Route path="/login" component={LoginNews} />
         
        
            <Route exact path="/" component={LoginNews} />{" "}
            <Route exact path="/UplodeFile" component={NewUploadeFile} />{" "}
            
            <ProtectedRoute path="/Mustakdem" Component={NewUserForm} />{" "}
            <ProtectedRoute path="/HomeUserManage" Component={HomeUserManage} />{" "}
            <ProtectedRoute path="/HomeAdminManage" Component={HomeAdminManage} />{" "}

            <ProtectedRoute path="/addusers" Component={AddUsers} />{" "}
            <ProtectedRoute path="/updateusers" Component={UpdateUsers} />{" "}
            <ProtectedRoute path="/usersmange" Component={Users} />{" "}
            <Route exact path="/Files" component={Files} />
            <Route path="/FilesUsers" component={NewUserForm} />{" "}
            <Route path="/500" component={NoInternet} />{" "}
            <Route path="/MaliaUser" component={MaliaUser} />{" "}
            <Route path="/Malia" component={Malia} />{" "}
            <Route path="/MaliaUpdate" component={MaliaUpdate} />{" "}
            <Route path="/PrintKshf" component={PrintKshf} />{" "}
            <Route path="/NotiFy" component={NotiFy} />{" "}
            <Route path="/NotyUser" component={NotyUser} />{" "}
            <Route path="/Amal" component={Amal} />{" "}
            <Route path="/NewAmal" component={NewAmal} />{" "}
            <Route path="/Amaluser" component={Amaluser} />{" "}
          <Route path="/NotiFyAllUser" component={NotiFyAllUser} />{" "}
                        <Route path="/privacy-policy-ar" component={Privice} />{" "}

          </Switch>{" "}
         
        </Router>
     
    </div>
  );
};

export default App;
