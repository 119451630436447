import React, { useEffect, useState } from 'react';
import './style.css';
import { actiontype } from "../../reducer/Reducer";
import { useStateValue } from "../../reducer/StateProvider";
import SlideUsers from '../NewUserForm/SlideUsers';
import axios from "../../utils/API";
import {  InputNumber, Table, Typography } from 'antd';
import { useHistory } from 'react-router-dom';

import "react-datepicker/dist/react-datepicker.css";


const MaliaUser = () => {


     const history = useHistory();
    
    const idmk = localStorage.getItem("idmustakdem");

    const [username, setusername] = useState("");
    const [rqmadbara, setrqmadbara] = useState("");
  const [asemmshro, setasemmshro] = useState("");

    const [showslideuser, setshowslideuser] = useState(false);
     
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear(),
            hours = d.getHours(),
            ment = d.getMinutes();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
       const hm = hours + ":" + ment;
        return  hm + " " + [year, month, day].join('-');
    }

   const [datenow,setdatenow]=useState(formatDate(new Date()));

    const [datefrom ] = useState(localStorage.getItem("datefrom"));
    const [dateto] = useState(localStorage.getItem("dateto"));

    
    const [
        {
            clickiconmenufolder_REDUCER,
dtkshf
        },
        dispatch,
    ] = useStateValue();

    const setoverflowmenu = () => {
        dispatch({
            type: actiontype.SET_OVERMENUE,
            items: false,

        });

        dispatch({
            type: actiontype.SET_SHOWREdate,
            items: false,
        });

        dispatch({
            type: actiontype.SET_SHOWADDFOLDERFORM,
            items: false,
        });
        setshowslideuser(false);
    };
   

 
    useEffect(() => {
        const usermustakem = async () => {
            const data = await axios.post(`users/${idmk}`);
            setusername(data.data.username);
            setrqmadbara(data.data.rqmadbara);
            setasemmshro(data.data.asemmshro);


        }
        usermustakem(idmk);

    }, [idmk]);


    const { Text } = Typography;

    const columns = [
        {
            title: 'التاريخ',
            dataIndex: 'date',
            width: 120,
            align: 'center',
        },
        {
            title: 'مدين/طلب',
            dataIndex: 'mden',
            width: 120,
            align: 'center',


            className: 'column-money thtabls',
             render: (text, record) => (
      
       
         <InputNumber
          style={{width: "100%",border:"none"}}
                            value={record.mden} 
                          
            bordered={false}
            readOnly
      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
    parser={value => value.replace(/\D/g, "")}
    />
     
    ),
        },
        {
            title: 'دائن/تسديد',
            dataIndex: 'daen',
            width: 150,
            align: 'center',
            className: 'column-money',
            
    render: (text, record) => (
      
       
         <InputNumber
          style={{width: "100%",border:"none"}}
                            value={record.daen} 
                          
            bordered={false}
            readOnly
      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
    parser={value => value.replace(/\D/g, "")}
    />
     
    ),
        },
     
        {
            title: 'البيان',
            dataIndex: 'byean',
            align: 'center',



        },
         
    ];


    useEffect(() => {
       
      
        //fetchdatamalia(idmk);
    },[idmk])










            
   
  
    useEffect(() => {
        if (dtkshf.length===0) {
            history.push("/MaliaUser")
        }
    })


    return (
        <div>

            <div className="fixed">


            <div className="logo" style={{position:"relative"}}>
                        <img
                            src={window.location.origin + "/img/headerseadi.PNG"}
                            style={{ maxHeight: "100px",width:"100%" }}
                            className="logoinuserform"
                            alt="logo"
                        />
                        <div className="bp" style={{position:"absolute",top:"10%",right:"39%",fontWeight:600}}>
                        <div >كشف حساب المستخدم : {username}</div>
                         <div>اسم المشروع : {asemmshro}</div>
                        </div>

                        <div className="bp"  style={{position:"absolute",top:"41%",right:"39%",fontWeight:600}}>
                        <div >من الفترة : {datefrom} الى الفترة : {dateto}</div>
                         <div>رقم الاضبارة: {rqmadbara}</div>
                        </div>
                        
                         
                  
                
                    </div>

               
               


            

            </div>
            <div className="husabmustakem">

                <Table
                    
                   
                    
                  
                    className="ant-table-thead2"
                    columns={columns}
                    dataSource={dtkshf}
                    pagination={false}
                    
                    summary={pageData => {
                        let totalmden = 0;
                        let totaldaen = 0;
                      

                        pageData.forEach(({ mden, daen, amla }) => {
                            totalmden += amla === "دينار عراقي" ? parseFloat(mden)  : 0;
                            totaldaen += amla === "دينار عراقي" ? parseFloat(daen) : 0;
                           
                        });

                        return (
                            <>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell className="text-center mblgiq">المبلغ الكلي</Table.Summary.Cell>
                                    <Table.Summary.Cell className="text-center mblgiq">
                                       
                                       
      
       
         <InputNumber
          style={{width: "100%",border:"none"}}
                            value={totalmden} 
                          
            bordered={false}
            readOnly
      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
    parser={value => value.replace(/\D/g, "")}
    />
     
  
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell className="text-center mblgiq">
                                      
                                        
       
         <InputNumber
          style={{width: "100%",border:"none"}}
                            value={totaldaen} 
                          
            bordered={false}
            readOnly
      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
    parser={value => value.replace(/\D/g, "")}
    />
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell className="mblgiq">
                                        <Text >دينار عراقي</Text>
                                    </Table.Summary.Cell>

                                
                                </Table.Summary.Row>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell className="mblgiq">الرصيد</Table.Summary.Cell>
                                    <Table.Summary.Cell colSpan={2} className="mblgiq">
                                       
                                     <Text className="d-flex">
                                        <InputNumber
                                            style={{ width: "100%", border: "none" }}
                                            value={totalmden - totaldaen}
                          
                                            bordered={false}
                                            readOnly
                                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')  }
    parser={value => value.replace(/\D/g, "")}
                                        /> {totaldaen  > totalmden ? "دائن" : "مدين"}
                                        </Text>
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>
                             
                            </>
                        );
                    }}
                />

            </div>
            
            <div className="logo" style={{position:"relative"}}>
               
               <div  style={{ width:"100vw",position:'fixed',bottom:0,right:0,left:0 }}>
               <div style={{position:"absolute",top:"-100px",right:"100px"}}>اسم المستلم و التاريخ</div>
                <div style={{position:"absolute",top:"-100px",left:"100px"}}>اسم مسٌلم المبلغ و التاريخ</div>
                <div style={{position:"absolute",top:"-30px",left:"45%"}}>{datenow}</div>

                <img
                            src={window.location.origin + "/img/footerseadi.JPG"}
                           
                            className="logoinuserform divid"
                            alt="logo"
                        />
               </div>
                        
                    </div>

            {clickiconmenufolder_REDUCER ? (
                <div className="overfloeiconfolder" onClick={setoverflowmenu}></div>
            ) : (
                ""
            )}
            {showslideuser ? <SlideUsers clas="SlideUsers" /> : ""}


        </div>
    )
}

export default MaliaUser


