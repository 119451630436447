import React from 'react';
import {render} from 'react-dom';
import App from './App';
import reducer,{initialState} from './reducer/Reducer';
import { StateProvider } from './reducer/StateProvider';
import "./style.css";
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'

/*
import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';


import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { pwaTrackingListeners } from './PWAEventListeners';
*/

render(
  <React.StrictMode>
  <StateProvider  initialState={initialState} reducer={reducer}>
    <App />
  </StateProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

/*
serviceWorkerRegistration.register();
pwaTrackingListeners();
*/