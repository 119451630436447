import React, { useEffect, useState } from "react";
import "./stylemangeusermobail.css";

import { AiOutlineFileSearch } from "react-icons/ai";
import { ImAddressBook } from "react-icons/im";
import { FaSearchDollar } from "react-icons/fa";
import { MdNotificationsActive } from "react-icons/md";
import { RiLogoutCircleRFill } from "react-icons/ri";
import axios from "../../utils/API";
import { useHistory } from "react-router";




const HomeUserManage = () => {

  const [username, setusername] = useState("");
  const idmk = localStorage.getItem("idmustakdem");

  const [notyuser, setnotyuser] = useState(0);
  const history = useHistory();


  const userones = async (iduss) => {
    const data = await axios.post(`users/${iduss}`);
    setusername(data.data.username);
      
    const datanoty = await axios.get(`NotyUser/${iduss}`);
    if (datanoty.data[0].notify.length > 0) {
        
      const lennoty = datanoty.data[0].notify.filter(res => res.red === false).map(res => res)
      setnotyuser(lennoty.length);
    }
    else {
      setnotyuser(0);
    }
  };




  useEffect(() => {
    userones(idmk);
      
  }, [idmk]);

  const gotoamaluser = () => {
    history.push("/Amaluser", { from: "HomeUserManage" });
  }

  const GoToFiles = () => {
    history.push("/Mustakdem", { from: "HomeUserManage" });
  }

  const gotoMaliaUser = () => {
    history.push("/MaliaUser", { from: "HomeUserManage" });
  }
  const [allnotyuser, setallnotyuser] = useState([{}]);

  useEffect(() => {
    //setkey(idwsel);
    const usermustakem = async () => {
        
      const datanoty = await axios.get(`NotyUser/${idmk}`);
      setallnotyuser(datanoty.data[0].notify);

    }
    usermustakem(idmk);

  }, []);
  



     
    
    const gotonotyuser = async () => {
   

      allnotyuser.map(res =>
  
        !res.red&&
        axios.put("NotyUser/", {
          iduser: idmk,
          key: res.key,
          date: new Date(),
          red: true

        })
      )
  
      setTimeout(() => {
              history.push("/NotyUser", { from: "HomeUserManage" });

      }, 1000);

    }
    
    const loguotuser = async () => {
      localStorage.setItem("LOGINUSER", false);
      localStorage.removeItem("idusee");
      localStorage.removeItem("token");

      history.push("/login", { from: "HomeUserManage" });

    };
  
    return (
      
     <div style={{height: "100vh",background:"linear-gradient(0deg, rgb(203, 225, 228) 0%, rgb(255 255 255) 100%)" }}>
         <img
            src={window.location.origin + "/img/bkdash.svg"}
            className="imgbkdash"
            alt="imgbkdash"
          />
        <div className="MainContainer2">
              مرحبا بك : {username}
          </div>
      <div className="MainContainer">
          

      <div
        className="box"
        style={{ backgroundColor: "rgb(35 49 130)", color: "#fff" }}
      onClick={GoToFiles}>
        <AiOutlineFileSearch size={50} />
        <span>الملفات</span>
      </div>
      <div
        className="box"
        style={{ backgroundColor: "#e89925", color: "#fff" }}
       onClick={gotoamaluser}>
        <ImAddressBook size={40} />
        <span>عمل كتابة</span>
      </div>
      <div
        className="box"
        style={{ backgroundColor: "#de3b07d6", color: "#fff" }}
       onClick={gotoMaliaUser}>
        <FaSearchDollar size={50} />
        <span>كشف حساب</span>
      </div>
      <div
        className="box"
        style={{ backgroundColor: "#06803d", color: "#fff" }}
       onClick={gotonotyuser}>
                    <MdNotificationsActive size={50} />
                     <div className="notiuser1">{notyuser}</div>
        <span>الاشعارات</span>
      </div>
      
      <div
        className="box"
        style={{ backgroundColor: "rgb(64 64 64)", color: "#fff" }}
      onClick={loguotuser}>
        <RiLogoutCircleRFill size={50} />
        <span>تسجيل الخروج</span>
      </div>
            </div>
            </div>
  );
};

export default HomeUserManage;
