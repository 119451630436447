import { message, Progress } from 'antd';
import React, { useEffect, useState } from 'react'
import { AiFillCloseCircle } from 'react-icons/ai';
import { actiontype } from '../../reducer/Reducer';
import { useStateValue } from '../../reducer/StateProvider';
import axios from "../../utils/API";
import openMessage from './openMessage';


const Rename = ({namefolder,idfolder}) => {

    const [,dispatch] = useStateValue();
    const [filename, setfilename] = useState(namefolder);
     const [progess, setprogess] = useState(0);

const userid = localStorage.getItem("idmustakdem");
const useridAuth = localStorage.getItem("idusee");
    const closerenameform =() =>{
        dispatch({
            type: actiontype.SET_OVERMENUE,
            items:false,
          });
    
          dispatch({
            type: actiontype.SET_SHOWRENAME,
            items:false,
          });
    }

    const updatefolderes =async () => {
        //const userone = await axios.post(`users/${1}`);
    
        try {
          const userone = await axios.post(`users/${useridAuth}`);
          if (userone.data.permistoin_users[0].updatefolder==="لا") {
            message.error("لا تمتلك صلاحية تعديل المجلد")
          }
          else
          {
            axios.put("folder", {
                iduser: userid,
                idfolder: idfolder,
                namefolder: filename,
                empollyadd: "osamah",
                hystory: "",
              }, {

                onUploadProgress: progressEvent =>
               
                 setprogess(
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        ), 
              });
              closerenameform();
              openMessage();
              //alert("تم التعديل")
            }
        } catch (error) {
            //console.log(error);
        }
       
           
        }
     
  
   useEffect(() => {
    if (progess === 100) {
     setTimeout(() => {
      message.success("تم تعديل اسم المجلد")
     setprogess(0);
     }, 1000);
    
   }
  }, [progess])
    
        //console.log(progress);

    return (
        <div className="rename">
            
            <div className="renameheader"><h4>اعادة تسمية </h4> <AiFillCloseCircle onClick={closerenameform} size={30}/></div>

            <input placeholder="يرجى ادخال الاسم هنا . " className="inputrename" type="text" value={filename} onChange={(e)=>setfilename(e.target.value)}/>

                <div className="btnrename">
                <button onClick={updatefolderes}>حفظ</button> <button onClick={closerenameform}>الغاء</button>
                </div>

            
        
                <div className="progress">
           <Progress percent={progess} />
          </div>


        </div>
    )
}

export default Rename
