import React, { useEffect, useState } from "react";
import { RiMenuFill } from "react-icons/ri";
import axios from "../../utils/API";
import SlideUsers from "../NewUserForm/SlideUsers";
import { actiontype } from "../../reducer/Reducer";
import { useStateValue } from "../../reducer/StateProvider";
import { message, Progress } from "antd";
import { v4 as uuidv4 } from "uuid";
import { useHistory } from "react-router";

const NewAmal = () => {
  const history = useHistory();
  const [{ clickiconmenufolder_REDUCER }, dispatch] = useStateValue();

  const iduser = localStorage.getItem("idusee");
  const idmk = localStorage.getItem("idmustakdem");

  const [btnadd, setbtnadd] = useState(false);
  const [progess, setprogess] = useState(0);
  const [MSG, setMSG] = useState("");
  const [showslideuser, setshowslideuser] = useState(false);
  const [username, setusername] = useState("");

  const [titalnoty, settitalnoty] = useState("");
  const [opjnoty, setopjnoty] = useState("");
  const [key, setkey] = useState(uuidv4());

  const showsleider = async () => {
    setshowslideuser(true);
    dispatch({
      type: actiontype.SET_OVERMENUE,
      items: true,
    });
  };

  const setoverflowmenu = () => {
    dispatch({
      type: actiontype.SET_OVERMENUE,
      items: false,
    });

    dispatch({
      type: actiontype.SET_SHOWREdate,
      items: false,
    });

    dispatch({
      type: actiontype.SET_SHOWADDFOLDERFORM,
      items: false,
    });
    setshowslideuser(false);
  };

  useEffect(() => {
    //setkey(idwsel);
    const usermustakem = async () => {
      const data = await axios.post(`users/${iduser}`);
      setusername(data.data.username);
    };
    usermustakem(idmk);
  }, [idmk]);

  useEffect(() => {
    if (progess === 100) {
      setTimeout(() => {
        message.success(MSG);
        setprogess(0);
      }, 1000);
    }
  }, [progess]);

  const NewAdd = () => {
    setkey(uuidv4());
    setbtnadd(false);
    settitalnoty("");
    setopjnoty("");
  };

  const AddNoty = async () => {
    if (opjnoty === "") {
      message.error("يرجى تحديد الموضوع");
    } else {
      axios.post(
        "Amal/",
        {
          nameuser: username,
          iduser: iduser,
          note: opjnoty,
          date: new Date(),
          red: false,
          key: key,
        },
        {
          onUploadProgress: (progressEvent) =>
            //console.log(Math.round((progressEvent.loaded * 100) / progressEvent.total));
            setprogess(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            ),
        }
      );
      setMSG("تم اضافة اعمال جديدة");
      setbtnadd(true);
    }
  };

  const getoamal = async () => {
    const userone = await axios.post(`users/${iduser}`);
    if (userone.data.role === "مدير نظام") {
      history.push("/Amal", { from: "ProtectedRoute" });
    } else {
      history.push("/", { from: "ProtectedRoute" });
    }
  };

  return (
    <div>
      <div className="fixed">
        <div className="navbar">
          <h3 style={{ color: "#FFF" }}>اضافة الاعمال</h3>
          <div className="btn_amal_user active" onClick={getoamal}>
            جدول الاعمال
          </div>
        </div>

        <div className="header2" style={{ justifyContent: "center" }}>
          اضافة عمل جديد
        </div>
      </div>

      <div className="notify_main">
        <div style={{ width: "100%", textAlign: "center" }}>
          <div>
            {" "}
            <span>الموضوع :</span>{" "}
          </div>
          <textarea
            className="inputmblg"
            style={{ height: 200 }}
            value={opjnoty}
            onChange={(e) => setopjnoty(e.target.value)}
          ></textarea>

          <Progress percent={progess} />

          <div className={progess > 0 ? "btnmblgzero" : "btnmblg"}>
            <div className="btnmb btnupdate" onClick={NewAdd}>
              جديد
            </div>
            <div className={btnadd ? "btnmbzro" : "btnmb "} onClick={AddNoty}>
              اضافة
            </div>
          </div>
        </div>
      </div>

      {clickiconmenufolder_REDUCER ? (
        <div className="overfloeiconfoldermal" onClick={setoverflowmenu}></div>
      ) : (
        ""
      )}
      {showslideuser ? <SlideUsers clas="SlideUsers" /> : ""}
    </div>
  );
};

export default NewAmal;
