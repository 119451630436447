
import React, { useState,useEffect } from 'react';


import axios from "../../utils/API";
import { Progress } from 'antd';
import { useHistory } from 'react-router-dom';
import openMessage,{WrongFilesMsg} from './openMessage';
import { FaHandPointUp } from 'react-icons/fa';
import { ImUpload } from 'react-icons/im';
import { AiFillHome } from 'react-icons/ai';
import ResultS from './ResultS';
import { Button } from 'antd';
import { VscFilePdf } from "react-icons/vsc";
import { v4 as uuidv4 } from "uuid";

import {
  
  AiOutlineFileImage,
  AiOutlineFileJpg,
} from "react-icons/ai";
import {
  
  RiFile2Fill
 
} from "react-icons/ri";


const NewUploadeFile = () => {

const useridAuth = localStorage.getItem("idusee");
const userid = localStorage.getItem("idmustakdem");
  const idfolder = localStorage.getItem("idfolder");
  const namefolders = localStorage.getItem("namefolder");

  const [username, setusername] = useState("");


  const [state, setState] = useState({file: ""});
   const [progess, setprogess] = useState(0);


  const fileUpload = (file) => {
    const url = "files/upload";
    const formData = new FormData();
    for (const key of Object.keys(state.file)) {
      formData.append("imgCollection", state.file[key]);
    }
    formData.append("iduser", userid);
    formData.append("idfolders", idfolder);

    return axios.post(url, formData,{

                onUploadProgress: progressEvent =>
               
        //console.log(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                setprogess( Math.round( (progressEvent.loaded * 100) / progressEvent.total ))
               
              });
  };
    
const onChange = (e) => {
  setState({ file: e.target.files });
  //console.log(state)
  };

  useEffect( () => {
    
    const getusername =async()=>{
      const data = await axios.post(`users/${userid}`);
      setusername(data.data.username);
    }
    getusername();
    
    });

 
  const AddNoty =  () => {
 
      axios.post(
        "Amal/",
        {
          nameuser: username,
          iduser: userid,
          note: "تم اضافة ملف جديد",
          date: new Date(),
          red: false,
          key: uuidv4(),
        }
      );
    
    
  };

  const upload = async (e) => {
//e.persist()
    //const userone = await axios.post(`users/${userid}`);
    
    if(state.file===""){
      WrongFilesMsg();
    }
    else
    {
      fileUpload(state.file).then((response) => { });
      //console.log(progess)
      AddNoty();
      
       
    }
        
        
  };

  
  
       useEffect(() => {
    if (progess === 100) {
     setTimeout(() => {
  openMessage();
     //setprogess(0);
     }, 1000);
    
   }
  }, [progess])
      
  const sfdata=[];
  try {

   // const sdata = state.Array.map((res) => res.file)
    //const sdata = [{}]
   sfdata.push(Array.from(state.file));
      
   
  } catch (error) {
    
  }
 

 const history = useHistory();
  

  const   GoToUplodeFile = () => {
    history.push("/UplodeFile", { from: "/UplodeFile" });
    setprogess(0);
    setState({ file: "" });
   }

   const   GoToHome = async () => {
    const userone = await axios.post(`users/${useridAuth}`);
    if (userone.data.role === "مدير نظام") {
        history.push("/FilesUsers", { from: "/UplodeFile" });
      } else {
        history.push("/Mustakdem", { from: "/UplodeFile" });
      }

  
   }

    
 //console.log(sfdata[0])



const removeitem =()=>{
    var files = sfdata[0];
    
    
    for (let index = 0; index < files.length; index++) {
        const element = files[index];
         console.log(element);


        
    }
}
    return (
        <div className="mainupload">
        <div className="headeruploadefile">
        <div>عدد الملفات {sfdata[0].length}</div>
        <div disabled={progess>0?true:false} onClick={GoToHome} className="closeupload">X</div>
        </div>

        <div className="bodyuploadefile">

{progess===100?
         <div className="resultuploadfile">
         <ResultS/>
         <Button onClick={GoToHome} type="primary" key="console">
          الرجوع الى الصفحة الرئيسية<AiFillHome size={20} className="mr-2"/>
          </Button>,
          <Button onClick={GoToUplodeFile} key="buy">
           رفع ملفات اخرى الى المجلد  ( {namefolders} )<AiFillHome size={20} className="mr-2"/>
          </Button>
          </div>
         
         :
         
         <>
         {sfdata[0].length===0?
         <>
          <input
                  type="file"
                  onChange={(e) => onChange(e)}
                  multiple
                  style={{background: "#ffeb3b", border: "1px solid black"}}
          id="fileup"
                hidden
                accept = "audio/* , video/* , image/* , .pdf, .doc , .xls,.xlsx , .docx"
        />
        <label className="selectfile " htmlFor="fileup">
          <FaHandPointUp size={40} className="mb-2"/>
          اضغط هنا ليتم اختيار الملفات 
          </label>
         
       
          </>
           :
           <div className="detfiledata">
          {sfdata[0].map((res,i) =>
           <div key={i} className='fileuploaddials'>
           
           <div>
           {res.type === "image/jpeg" ? (
                  <AiOutlineFileJpg
                    className="svgicon"
                    size={25}
                    fill="#2792bf"
                  />
                ) : res.type === "image/png" ? (
                  <AiOutlineFileImage
                    className="svgicon"
                    size={25}
                    fill="#2792bf"
                  />
                ) : res.type === "application/pdf" ? (
                  <VscFilePdf className="svgicon" size={25} fill="#2792bf" />
                ) : (
                  <RiFile2Fill className="svgicon" size={25} fill="#2792bf" />
                )}
           
           </div>
           <div className="detailsfile">
           <div>{res.name} </div>
           <div>size:{Math.round(res.size/1024)}Kb</div>
           </div>
           </div>
           
        )}
        </div>
        
        }

        </>
        
       
}
        </div>

<Progress percent={progess} style={{padding:"0 10px"}}/>

        

         <div className="footeruploadefile">
         
          
 

         <button className="btnuploadfile" style={{background:"#259425"}} disabled={progess>0?true:false}>
         <input
                  type="file"
                  onChange={(e) => onChange(e)}
                  multiple
                  style={{background: "#ffeb3b", border: "1px solid black"}}
          id="fileup2"
                hidden
                accept = "audio/* , video/* , image/* , .pdf, .doc , .xls,.xlsx , .docx"
           disabled={progess>0?true:false}
                
        />
           <label style={{margin:0}} htmlFor="fileup2" disabled={progess>0?true:false}>
           اضافة ملفات 
          <FaHandPointUp size={20}/>
          </label>

            
                </button>

                <button className="btnuploadfile"   disabled={progess>0?true:false} onClick={upload}>
            رفع الملفات <ImUpload size={20} /> 
                </button>



        </div>








    
            
        </div>
    )
}


export default NewUploadeFile